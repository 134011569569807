const defaultParticipantValues = {
  preferred_contact_method: ``,
  preferred_contact_time: ``,
  status: ``,
  connected_apps: [],
  email: ``,
  enrollment_identifier: ``,
  tags: ``,
  participant_auth_token: ``,
  phone_number: ``,
  loading: false,
  messages: [],
};

export const participant = (
  state = defaultParticipantValues,
  { type, payload = {} },
) => {
  switch (type) {
    case 'USER/LOGOUT':
      return { ...defaultParticipantValues };
    case 'USER/LOGIN':
    case 'USER/UPDATE_ACM':
    case 'SURVEY/SUBMIT_SUCCESS':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const formEnroll = hasAcmCookie => {
  return [
    {
      input: [
        {
          name: `email`,
          placeholder: `Enter Email Address`,
          interface: `email`,
          inputStyle: `achievement`,
          required: !hasAcmCookie,
          hidden: hasAcmCookie,
        },
      ],
    },
  ];
};

const formLogin = () => {
  return [
    {
      input: [
        {
          name: `email`,
          placeholder: `Enter Email Address`,
          interface: `email`,
          inputStyle: `achievement`,
          required: true,
        },
        {
          name: `password`,
          placeholder: `Enter Your Achievement Password`,
          interface: `passwordOnly`,
          inputStyle: `achievement`,
          required: true,
        },
      ],
    },
  ];
};

export default { formEnroll, formLogin };

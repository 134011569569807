import BasicStyledModal from '../../../components/styledModal';
import { Link } from 'react-router-dom';
import Login from './logIn';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoginLink = styled.div`
  display: flex;

  p,
  a {
    font-size: 14px;
    font-weight: 200;
    padding: 0 5px;
    margin-top: 10px;
  }
`;

class CheckEligibilityModal extends React.Component {
  state = {
    isOpen: false,
    opacity: 0,
  };

  showModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  afterOpen = () =>
    setTimeout(() => {
      this.setState({ opacity: 1 });
    });

  beforeClose = () =>
    new Promise(resolve => {
      this.setState({ opacity: 0 });
      setTimeout(resolve, 200);
    });

  render() {
    return (
      <React.Fragment>
        {this.props.render({ onClick: this.showModal })}
        <BasicStyledModal
          isOpen={this.state.isOpen}
          afterOpen={this.afterOpen}
          beforeClose={this.beforeClose}
          closeModal={this.closeModal}
          opacity={this.state.opacity}
          header={this.props.title}
          content={this.props.content}
        >
          <Login
            id="check_elgibility_login"
            promptForLogin={false}
            hero_button={this.props.hero_button}
          />
          <LoginLink>
            <p>Already have an Achievement account?</p>
            <Link style={{ whiteSpace: 'nowrap' }} to="/login">
              Log in.
            </Link>
          </LoginLink>
        </BasicStyledModal>
      </React.Fragment>
    );
  }
}

CheckEligibilityModal.defaultProps = {
  render: () => {},
};

CheckEligibilityModal.propTypes = {
  render: PropTypes.func.isRequired,
};

export default CheckEligibilityModal;

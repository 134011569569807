import _ from 'lodash';

export const landing = (state = {}, { type, payload }) => {
  switch (type) {
    case 'LANDING/RECEIVE':
      return { ...state, ..._.pick(payload, ['nodes', 'layout']) };
    default:
      return state;
  }
};

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var InputError = _styledComponents["default"].div.withConfig({
  displayName: "InputError",
  componentId: "sc-175k9sb-0"
})(["font-size:14px;padding-top:2px;margin-bottom:16px;color:#b21f36;display:block;"]);

InputError.propTypes = {
  style: _propTypes["default"].object
};
InputError.defaultProps = {
  style: {}
};
var _default = InputError;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReactMediaQueries = void 0;
var ReactMediaQueries = {
  mobile: '(max-width: 599px)',
  desktop: '(min-width: 600px)'
};
exports.ReactMediaQueries = ReactMediaQueries;
import React from 'react';
import styled from 'styled-components';
import { mobileOnly } from '../headerLinks';
import hamburger from './hamburger';

const Wrapper = styled.div`
  ${hamburger}
`;

const HamburgerButton = styled.button`
  ${mobileOnly};
  display: flex !important;
  padding: 0 !important;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const Hamburger = ({ active, onClick }) => (
  <Wrapper>
    <HamburgerButton
      className={'hamburger hamburger--squeeze' + (active ? ' is-active' : '')}
      onClick={onClick}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </HamburgerButton>
  </Wrapper>
);

export default Hamburger;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default() {
  var intf = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

  switch (intf.toLowerCase()) {
    case "contentacceptance":
    case "content_acceptance":
      return "contentacceptance";

    case "height":
      return "heightinput";

    case "scrollboxlarge":
      return "scrollboxlarge";

    case "radio":
    case "radioinput":
      return "radioinput";

    case "select":
    case "selectinput":
      return "selectinput";

    case "textarea":
    case "textareainput":
      return "textareainput";

    case "checkbox":
    case "checkboxinput":
    case "checkboxgroup":
      return "checkbox";

    case "checkboxwithnone":
      return "checkboxgroupwithnone";

    case "zipcode":
    case "zip_code":
    case "zip":
      return "zip";

    case "mask":
      return "maskinput";

    case "tel":
    case "phone":
      return "telinput";

    case "input":
    case "textinput":
      return "textinput";

    case "email":
      return "emailinput";

    case "passwordonly":
      return "passwordonly";

    case "password":
    case "passwordconfirm":
      return "password";

    case "time":
      return "time";

    default:
      // this is a catch all for ones that do not need transoformation
      // fields.js does a lowecase on the string anyways, so it's not always
      // required to return a special transformation
      return intf.toLowerCase();
  }
};

exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.match");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailValidation = exports.validateEquality = exports.requiredMessage = exports.emailRegex = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.emailRegex = emailRegex;
var requiredMessage = "Your email address does not appear to be valid";
exports.requiredMessage = requiredMessage;

var validateEmail = function validateEmail() {
  var email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return email.match(emailRegex);
};

var validateEquality = function validateEquality(value, allValues, props, name) {
  var ext = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "_confirm";
  var errorMessage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "Please confirm you typed your email address correctly";

  if (_lodash["default"].has(allValues, name) && _lodash["default"].has(allValues, "".concat(name).concat(ext))) {
    if (!_lodash["default"].isEqual(allValues[name], allValues["".concat(name).concat(ext)])) {
      return errorMessage;
    }
  }
};

exports.validateEquality = validateEquality;

var emailValidation = function emailValidation(v) {
  if (v && !validateEmail(v)) {
    return requiredMessage;
  }

  return undefined;
};

exports.emailValidation = emailValidation;
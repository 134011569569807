import * as L from './loadables';

/*eslint no-unexpected-multiline: 0*/
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  getUrlParams,
  pathContains,
  storeEnrollmentLocalStorage,
} from './utils';

import ErrorBoundary from './errorBoundary';
import FourOhFour from './routes/404';
import { Helmet } from 'react-helmet';
import LoadingIndicator from './components/loadingIndicator';
import MainLayout from './components/mainLayout';
import PropTypes from 'prop-types';
import Toastr from 'react-redux-toastr';
import _ from 'lodash';
import { actions } from './redux';
import api from './services';
import { connect } from 'react-redux';
import detector from 'detector';
import { withRouter } from 'react-router';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: ``,
    };
    this.props.clearErrors();
    window.onpopstate = this.onBackButtonEvent;
    this.props.establishUser(api.localStorage.get());
    if (props.prefetch) this.fetchData();
  }

  fetchData = async () => {
    const storage = api.localStorage.get();
    const promises = [this.props.getLanding()];

    if (this.isSurvey()) {
      const node_slug = this.props.location.pathname.split('/')[2];
      const urlParams = getUrlParams(window.location.search);
      promises.push(this.props.getSurvey(node_slug, urlParams));

      this.props.setUser({
        enrollment_identifier: urlParams.enrollment_identifier,
        auth_token: urlParams.auth_token,
      });
    } else if (
      _.has(storage, 'enrollment_identifier') &&
      _.has(storage, 'participant_auth_token')
    ) {
      this.props.getMeta(
        storage.participant_auth_token,
        storage.enrollment_identifier,
      );
      const { payload, error = false } = await this.props.getUser(
        storage.enrollment_identifier,
        storage.participant_auth_token,
      );
      if (!error) storeEnrollmentLocalStorage(payload);
    }

    await Promise.all(promises);
    this.setState({ loading: false });
  };

  isSurvey = () =>
    this.props.location.pathname &&
    this.props.location.pathname.split('/')[1] === 'surveys';

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.participant.enrollment_identifier &&
      this.differentNodes(nextProps)
    ) {
      this.evaluateRoute(nextProps);
    } else if (nextProps.meta.failure) {
      window.location = 'https://www.myachievement.com/';
    }
  }

  differentNodes = nextProps =>
    nextProps.participant.nodes !== this.props.participant.nodes;

  onBackButtonEvent = e => {
    e.preventDefault();
    this.getAllData();
  };

  generateGetRequestQueryParams = url_params => {
    return _.map(url_params, (value, field) =>
      field && value ? `${field}=${value}&` : null,
    ).join('');
  };

  getAllData = async () => {
    const requests = [this.props.getLanding()];

    if (!this.props.participant.enrollment_identifier) {
      const storage = api.localStorage.get();
      if (storage) this.props.establishUser(storage);
    }

    if (this.props.participant.enrollment_identifier) {
      const {
        enrollment_identifier,
        participant_auth_token,
      } = this.props.participant;

      requests.push(
        this.props.getMeta(participant_auth_token, enrollment_identifier),
      );
      const { payload: user, error } = await this.props.getUser(
        enrollment_identifier,
        participant_auth_token,
      );
      if (!error) storeEnrollmentLocalStorage(user);
    }
    await Promise.all(requests);
    this.setState({ loading: false });
  };

  submitUserData = (user_data, transition = true) => {
    this.props.clearErrors();
    this.setState({ loading: transition });

    const url_params = getUrlParams(window.location.search);

    const {
      enrollment_identifier = ``,
      participant_auth_token = ``,
    } = this.props.participant;

    const payload = { ...user_data, ...url_params, client_info: detector };

    return this.props
      .updateUser(payload, enrollment_identifier, participant_auth_token)
      .then(({ payload, error }) => {
        this.setState({ loading: false });
        if (!error) storeEnrollmentLocalStorage(payload);
      });
  };

  submitScreenerData = (payload, transition = true) => {
    this.props.clearErrors();
    this.setState({ loading: transition });

    const {
      enrollment_identifier = ``,
      participant_auth_token = ``,
      nodes,
    } = this.props.participant;

    return this.props
      .submitScreener(
        payload,
        enrollment_identifier,
        nodes[0].slug,
        participant_auth_token,
      )
      .then(({ payload, error }) => {
        this.setState({ loading: false });
        if (!error) storeEnrollmentLocalStorage(payload);
      });
  };

  findNodes(props) {
    switch (true) {
      case _.has(props, 'participant') && _.has(props.participant, 'nodes'):
        return props.participant;
      case _.has(this.props.landing, 'nodes'):
        return this.props.landing;
      default:
        return { nodes: [], layout: '' };
    }
  }

  evaluateRoute = (newProps = this.props) => {
    const data = this.findNodes(newProps);

    const { history } = this.props;
    const currentPath = history.location.pathname;
    const { nodes } = data;
    const { layout } = data;
    const currentLayout = layout === `education` ? `` : layout;
    this.setState({ error: '' });
    if (nodes.length <= 0) {
      // if there are no nodes
      console.error('No nodes present');
      return history.replace(`/404`);
    }

    if (
      // need to stop the current layout from navigating away from settings/summary
      // || currentPath === `/` <- this allows us to have routing to education, but we dont have the data yet
      currentLayout === `dashboard` &&
      pathContains(currentPath, [`faq`, `settings`, `summary`, `dashboard`])
    ) {
      return false;
    } else if (
      // special case for Education layout
      // using layout here because we switch currentLayout to a blank string
      layout === `education` &&
      pathContains(currentPath, [`log-in`])
    ) {
      return false;
    } else if (pathContains(currentPath, [`terms`, `one_click_contribution`])) {
      // allow access to terms at any time
      return false;
    } else if (_.includes(currentLayout, currentPath)) {
      return false;
    } else if (layout === `follow_up`) return false;

    // console.info(`Transitioning to: ${currentLayout}${window.location.search}`);
    history.replace(
      `/${currentLayout}${window.location.search}${window.location.hash}`,
    );
  };

  mergeProps = (props, ...etc) => {
    const { error } = this.state;

    return {
      ...props,
      ...{ ...etc },
      evaluateRoute: this.evaluateRoute,
      mergeProps: this.mergeProps,
      submitUserData: this.submitUserData,
      submitScreenerData: this.submitScreenerData,
      getAllData: this.getAllData,
      error,
      ...this.props,
      match: props.match,
    };
  };

  commonRender = (Module, props) => {
    const { nodes } = this.props.participant.nodes
      ? this.props.participant
      : this.props.landing;

    if (!nodes || nodes.length === 0) {
      return <FourOhFour />;
    }

    return Module && nodes.length >= 1 ? (
      <Module {...this.mergeProps(props)} />
    ) : (
      <LoadingIndicator />
    );
  };

  render() {
    const {
      meta,
      participant: { email, phone_number },
    } = this.props;
    const { loading } = this.state;

    if (meta.is_program) {
      return (
        <ErrorBoundary>
          <L.Program {...this.props} />
        </ErrorBoundary>
      );
    }

    const onLoad = `
    zE('webWidget', 'prefill', {
      email: {
        value: '${email}',
        readOnly: true
      },
    });
    `;
    return (
      <MainLayout loading={loading}>
        <Helmet>
          <meta content={meta.keywords} name="keywords" />
          <meta content="A study run by Evidation Health" name="description" />
          <meta content="Evidation Health" name="author" />
          <meta content="help@myachievement.com" name="contact" />
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=84b6e074-9852-49f5-9145-5aebe426dfe3"
            onload={email && onLoad}
          />
          <script type="text/javascript">
            {`
              window.zESettings = {
                webWidget: {
                  zIndex: 999,
                  offset: {
                    vertical: '110px',
                    mobile: {
                      vertical: '120px'
                    }
                  },
                  contactForm: {
                    tags: ['${meta.slug}'],
                    fields: [
                      { id: 360007180893, prefill: { '*': '${meta.slug}'} },
                      { id: 360018244493, prefill: { '*': '${phone_number}' } },
                    ]
                  }
                }
              };
          `}
          </script>
        </Helmet>

        <ErrorBoundary>
          <Route
            exact
            path="/"
            render={props => this.commonRender(L.Education, props)}
          />
          <Route
            exact
            path="/surveys/:slug"
            render={props => this.commonRender(L.FollowUp, props)}
          />
          <Route
            exact
            path="/log-in"
            render={props => this.commonRender(L.LogIn, props)}
          />
          <Route
            path="/screener"
            render={props => this.commonRender(L.Enrollment, props)}
          />
          <Route
            path="/verify_email"
            render={props => this.commonRender(L.VerifyEmail, props)}
          />
          <Route
            path="/multi_factor_authenticate"
            render={props => this.commonRender(L.MultifactorAuth, props)}
          />
          <Route
            path="/visit_portal"
            render={props => this.commonRender(L.GenericNonDashboard, props)}
          />
          <Route
            path="/event_delay"
            render={props => this.commonRender(L.GenericNonDashboard, props)}
          />
          <Route
            path="/registration"
            render={props => this.commonRender(L.Enrollment, props)}
          />
          <Route
            path="/informed_consent"
            render={props => this.commonRender(L.Enrollment, props)}
          />
          <Route
            exact
            path="/thank-you"
            render={props =>
              _.isEqual(meta.slug, 'covid2020') ? (
                this.commonRender(L.ThankYou, props)
              ) : (
                <FourOhFour />
              )
            }
          />
          <Route
            path="/file_upload"
            render={props => this.commonRender(L.Enrollment, props)}
          />
          <Route
            exact
            path="/manage/dashboard"
            initalEntries={[{ key: 'informed_consent' }]}
            render={props => this.commonRender(L.Dashboard, props)}
          />
          <Route
            exact
            path="/manage/dashboard/contribution/:slug"
            render={props =>
              this.commonRender(L.Contribution, {
                ...props,
                getAllData: this.getAllData,
              })
            }
          />
          <Route
            exact
            path="/manage/dashboard/diary/:node_slug"
            render={props =>
              this.commonRender(L.Diary, {
                getAllData: this.getAllData,
                ...props,
              })
            }
          />
          <Route
            exact
            path="/manage/dashboard/diary/:node_slug/complete"
            render={props => this.commonRender(L.DiaryComplete, props)}
          />
          <Route
            path="/manage/settings"
            render={props => this.commonRender(L.Settings, props)}
          />
          <Route
            path="/manage/summary"
            render={props => this.commonRender(L.Summary, props)}
          />
          <Route
            path="/faq"
            render={props => this.commonRender(L.Faq, props)}
          />
          <Route
            path="/terminal_state"
            render={props => this.commonRender(L.TerminalState, props)}
          />
          <Route
            path="/withdrawn"
            render={props => this.commonRender(L.TerminalState, props)}
          />

          <Switch>
            <Route
              path="/one_click_contribution/error"
              render={props => this.commonRender(L.OneClickError, props)}
            />

            <Route
              path="/one_click_contribution"
              render={props => this.commonRender(L.OneClick, props)}
            />
          </Switch>
          <Route
            path="/login"
            exact
            render={() => <Redirect to={`/log-in`} />}
          />
          <Route
            path="/dashboard"
            exact
            render={() => <Redirect to={`/manage/dashboard`} />}
          />
          <Route
            path="/dashboard/contribution/:slug"
            exact
            render={() => (
              <Redirect to={`/manage/dashboard/contribution/:slug`} />
            )}
          />
          <Route
            path="/settings"
            exact
            render={() => <Redirect to={`/manage/dashboard`} />}
          />
          <Route
            path="/summary"
            exact
            render={() => <Redirect to={`/manage/summary`} />}
          />
          <Route
            path="/unauthorized"
            render={props => this.commonRender(L.Unauthorized, props)}
          />
          <Route
            path="/settings"
            exact
            render={() => <Redirect to={`/manage/dashboard`} />}
          />
          <Route
            path="/summary"
            exact
            render={() => <Redirect to={`/manage/summary`} />}
          />
          <Route
            path="/unauthorized"
            render={props => this.commonRender(L.Unauthorized, props)}
          />
          <Route path="/404" component={FourOhFour} />
          <Toastr position="top-center" />
        </ErrorBoundary>
      </MainLayout>
    );
  }
}

App.propTypes = {
  participant: PropTypes.object,
  history: PropTypes.object.isRequired,
  prefetch: PropTypes.bool,
};

App.defaultProps = {
  prefetch: true,
  errors: [],
};

export default withRouter(
  connect(
    ({ participant, meta, landing, errors }) => ({
      participant,
      meta,
      landing,
      errors,
    }),
    dispatch => ({
      logoutUser: user => dispatch(actions.logoutUser(user)),
      establishUser: user => dispatch(actions.establishUser(user)),
      updateUser: (user_data, enrollment_id, auth_token) =>
        dispatch(actions.updateUser(user_data, enrollment_id, auth_token)),
      loginUser: (user_data, enrollment_id, auth_token) =>
        dispatch(actions.loginUser(user_data, enrollment_id, auth_token)),
      getUser: (enrollment_id, auth_token) =>
        dispatch(actions.getUser(enrollment_id, auth_token)),
      setUser: (enrollment_id, auth_token) =>
        dispatch(actions.setUser(enrollment_id, auth_token)),
      createUser: user => dispatch(actions.createUser(user)),
      getMeta: (auth_token, enrollment_identifier) =>
        dispatch(actions.getMeta(auth_token, enrollment_identifier)),
      getSurvey: (slug, urlParams) =>
        dispatch(actions.getSurvey(slug, urlParams)),
      submitScreener: (
        contribution,
        enrollment_identifier,
        node_id,
        auth_token,
      ) =>
        dispatch(
          actions.submitScreener(
            contribution,
            enrollment_identifier,
            node_id,
            auth_token,
          ),
        ),
      getLanding: () => dispatch(actions.getLanding()),
      clearErrors: () => dispatch(actions.clearErrors()),
    }),
  )(App),
);

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.every");

require("core-js/modules/es.array.from");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.reduce");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.string.includes");

require("core-js/modules/es.string.iterator");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSum = exports.validateHasSum = exports.validateAllQuestionsAnswered = exports.validateIsObject = exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

/*
  generates a list of validations based on the field configuration, which get passed into the redux field
*/
var _default = function _default(props) {
  return [validateIsObject, validateAllQuestionsAnswered(props), validateHasSum(props), validateSum(props)];
};

exports["default"] = _default;

var validateIsObject = function validateIsObject(value) {
  if (value && !_lodash["default"].isObject(value)) return 'malformed data';
};

exports.validateIsObject = validateIsObject;

var validateAllQuestionsAnswered = function validateAllQuestionsAnswered(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value) {
      if (!Array.from(Array(7).keys()).map(function (v) {
        return "".concat(name, "_").concat(v + 1);
      }).every(function (v) {
        return _lodash["default"].includes(Object.keys(value), v);
      })) return 'not all questions have been answered';
    }
  };
};

exports.validateAllQuestionsAnswered = validateAllQuestionsAnswered;

var validateHasSum = function validateHasSum(_ref2) {
  var name = _ref2.name;
  return function (value) {
    if (value) {
      if (!_lodash["default"].has(value, name)) return 'no sum present';
    }
  };
};

exports.validateHasSum = validateHasSum;

var validateSum = function validateSum(_ref3) {
  var name = _ref3.name;
  return function (value) {
    if (_lodash["default"].has(value, name)) {
      var values = _lodash["default"].omit(value, name);

      if (value[name] !== _lodash["default"].reduce(values, function (acc, v) {
        return acc + v;
      }, 0)) return 'invalid sum';
    }
  };
};

exports.validateSum = validateSum;
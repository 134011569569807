import React from 'react';
import Loadable from 'react-loadable';
import LoadingIndicator from './components/loadingIndicator';

export const CommonLoadable = {
  loading: LoadingIndicator,
};

export const Program = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/program'),
});

export const Unauthorized = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/unauthorized'),
});

export const Education = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/education'),
});

export const LogIn = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/log_in'),
});

export const ThankYou = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('./routes/enrollment'),
    ThankYou: () => import('./routes/dashboard/thankyou'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const ThankYou = loaded.ThankYou.default;
    return (
      <Wrapper {...props}>
        <ThankYou />
      </Wrapper>
    );
  },
});

export const Enrollment = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('./routes/enrollment'),
    Screener: () => import('./routes/enrollment/screener'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const Screener = loaded.Screener.default;
    return (
      <Wrapper {...props}>
        <Screener {...props} />
      </Wrapper>
    );
  },
});

export const MultifactorAuth = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('./routes/enrollment'),
    MultifactorAuth: () => import('./routes/multifactor_auth'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const MultifactorAuth = loaded.MultifactorAuth.default;
    return (
      <Wrapper {...props}>
        <MultifactorAuth {...props} />
      </Wrapper>
    );
  },
});

export const VerifyEmail = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('./routes/enrollment'),
    VerifyEmail: () => import('./routes/enrollment/verify_email'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const VerifyEmail = loaded.VerifyEmail.default;
    return (
      <Wrapper {...props}>
        <VerifyEmail {...props} />
      </Wrapper>
    );
  },
});

export const GenericNonDashboard = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('./routes/enrollment'),
    GenericNonDashboard: () =>
      import('./routes/enrollment/generic_non_dashboard'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const GenericNonDashboard = loaded.GenericNonDashboard.default;
    return (
      <Wrapper {...props}>
        <GenericNonDashboard {...props} />
      </Wrapper>
    );
  },
});

export const Dashboard = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/dashboard'),
});

export const Settings = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/settings'),
});

export const Contribution = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/contribution'),
});

export const FollowUp = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/follow_up'),
});

export const Diary = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/diary'),
});

export const DiaryComplete = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/diary/complete'),
});

export const Summary = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/summary'),
});

export const Faq = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/faq'),
});

export const TerminalState = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/terminal_state'),
});

export const OneClickError = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/one_click_contribution/error'),
});

export const OneClick = Loadable({
  ...CommonLoadable,
  loader: () => import('./routes/one_click_contribution'),
});

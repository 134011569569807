"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.symbol");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.reduce");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.define-properties");

require("core-js/modules/es.object.define-property");

require("core-js/modules/es.object.get-own-property-descriptor");

require("core-js/modules/es.object.get-own-property-descriptors");

require("core-js/modules/es.object.keys");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var iterateOverInputs = function iterateOverInputs(arrayOfInputs) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(arrayOfInputs, function (acc, item, index) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, item.name, "".concat(path, "[").concat(index, "]")));
  }, {});
};

var iterateOverSinglePage = function iterateOverSinglePage(page) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(page, function (acc, _ref, index) {
    var _ref$name = _ref.name,
        name = _ref$name === void 0 ? "" : _ref$name,
        item = (0, _objectWithoutProperties2["default"])(_ref, ["name"]);

    if (!_lodash["default"].has(item, "input")) {
      return acc;
    }

    return _objectSpread(_objectSpread({}, acc), iterateOverInputs(item.input, "".concat(path, "[").concat(index, "]['input']").concat(name !== "" ? "['".concat(name, "']") : "")));
  }, {});
};

var _default = function _default(pages) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(pages, function (acc, page, index) {
    if (_lodash["default"].has(page, "input")) {
      // case where the form does not have nested pages
      return _objectSpread(_objectSpread({}, acc), iterateOverSinglePage([page], "".concat(path)));
    } else {
      return _objectSpread(_objectSpread({}, acc), iterateOverSinglePage(page, "".concat(path, "[").concat(index, "]")));
    }
  }, {});
};

exports["default"] = _default;
export const errors = (state = [], { type, payload = 'An error occurred' }) => {
  switch (type) {
    case 'ERRORS/ADD':
      return [payload].concat(state);
    case 'ERRORS/CLEAR':
      return [];
    default:
      return state;
  }
};

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logErrorWithTags = exports.logError = exports["default"] = void 0;

var _javascript = _interopRequireDefault(require("@appsignal/javascript"));

var _pluginBreadcrumbsConsole = require("@appsignal/plugin-breadcrumbs-console");

var _pluginBreadcrumbsNetwork = require("@appsignal/plugin-breadcrumbs-network");

var _pluginWindowEvents = require("@appsignal/plugin-window-events");

var _pluginPathDecorator = require("@appsignal/plugin-path-decorator");

var appsignal = {
  sendError: function sendError() {},
  createSpan: function createSpan() {},
  send: function send() {}
};

if (process.env.NODE_ENV !== "test") {
  var env = window.env || {};
  appsignal = new _javascript["default"]({
    key: env.APPSIGNAL,
    namespace: env.NAMESPACE
  });
  appsignal.use((0, _pluginBreadcrumbsConsole.plugin)());
  appsignal.use((0, _pluginBreadcrumbsNetwork.plugin)());
  appsignal.use((0, _pluginWindowEvents.plugin)());
  appsignal.use((0, _pluginPathDecorator.plugin)());
}

var _default = appsignal;
exports["default"] = _default;

var logError = function logError(error) {
  return appsignal.sendError(error);
};

exports.logError = logError;

var logErrorWithTags = function logErrorWithTags(error) {
  var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var span = appsignal.createSpan(function (span) {
    span.setError(error);
    return span.setTags(tags);
  });
  appsignal.send(span);
};

exports.logErrorWithTags = logErrorWithTags;
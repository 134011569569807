"use strict";

require("core-js/modules/es.array.slice");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPhone = void 0;

var formatPhone = function formatPhone(value, previousValue) {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }

    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
    }
  }

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }

  return '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
};

exports.formatPhone = formatPhone;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateFormat = exports.validateDate = exports["default"] = void 0;

var _dateFns = require("date-fns");

/*
  field level validations
*/
var _default = function _default(_ref) {
  var dob_format = _ref.dob_format;
  return [validateDate, validateFormat(dob_format)];
};

exports["default"] = _default;

var validateDate = function validateDate(value) {
  if (value) {
    var parsed = (0, _dateFns.parseISO)(value);
    return (0, _dateFns.isValid)(parsed) && (0, _dateFns.isDate)(parsed) ? undefined : 'Invalid date';
  }
};

exports.validateDate = validateDate;

var validateFormat = function validateFormat(format) {
  return function (value) {
    if (value) {
      switch (format) {
        case 'YYYY':
          return /^[0-9]{4}$/.test(value) ? undefined : 'Invalid date format, expects YYYY';

        case 'YYYY-MM':
          return /^[0-9]{4}-[0-9]{2}$/.test(value) ? undefined : 'Invalid date format, expects YYYY-MM';

        case 'YYYY-MM-DD':
        default:
          return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value) ? undefined : 'Invalid date format, expects YYYY-MM-DD';
      }
    }
  };
};

exports.validateFormat = validateFormat;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatePinLength = exports["default"] = void 0;

var _default = function _default(props) {
  return [validatePinLength(props)];
};

exports["default"] = _default;

var validatePinLength = function validatePinLength(_ref) {
  var _ref$pinLength = _ref.pinLength,
      pinLength = _ref$pinLength === void 0 ? 6 : _ref$pinLength;
  return function (value) {
    if (value && value.length < pinLength) return 'incomplete PIN';
    return undefined;
  };
};

exports.validatePinLength = validatePinLength;
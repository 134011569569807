import { BigBlueButton } from '../../../components/headerLinks';
import { Button } from '@evidation/form-elements';
import CuriousPanda from '../../../components/CuriousPanda';
import React from 'react';
import _ from 'lodash';

const LoginForm = ({ submit, config, ...rest }) => (
  <CuriousPanda
    enableReinitialize={true}
    destroyOnUnmount={true}
    keepDirtyOnReinitialize={true}
    forceUnregisterOnUnmount={false}
    pure={true}
    onSubmit={submit}
    {...rest}
  >
    {({ invalid, pristine, submitting, total = 0, page = 0, onChangePage }) => {
      const {
        hero_button,
        isLoading,
        displayPasswordLink,
        hasAcmCookie,
      } = config;
      // Logic to determine if submit should be deactivated
      // in this instance we are checking to see if we have initial values defined
      // as If we have already populated the form (for CRF ONLY), it is ready to be submitted
      // most other cases do not require this.
      let enabled = _.isEmpty(rest.initialValues)
        ? pristine || submitting
        : !invalid || submitting;
      if (hasAcmCookie) enabled = true;

      return (
        <React.Fragment>
          <BigBlueButton
            isLoading={submitting || isLoading}
            buttonStyle="achievement"
            id={`t-submit_button_${rest.form}`}
            type="submit"
            disabled={!enabled}
            hero={rest.hero}
          >
            {hero_button}
          </BigBlueButton>
          {displayPasswordLink && (
            <Button
              className="mt5"
              buttonStyle="achievement_text"
              onClick={e => {
                e.preventDefault();
                window.location.replace(
                  `https://www.myachievement.com/password`,
                );
              }}
            >
              Forgot your password?
            </Button>
          )}
        </React.Fragment>
      );
    }}
  </CuriousPanda>
);

LoginForm.defaultProps = {};

LoginForm.propTypes = {};

export default LoginForm;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropzoneContainer = exports.PreviewContainer = exports.UploadPicturesWrapper = exports.UploadPictureContainer = exports.DeleteImageOverlay = exports.RemoveHeading = exports.DeleteImage = exports.ErrorsContainer = exports.Container = exports.FileUploadLabel = exports.Uploader = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var Uploader = _styledComponents["default"].div.withConfig({
  displayName: "components__Uploader",
  componentId: "xbcsjd-0"
})(["width:100%;input{display:none;}"]);

exports.Uploader = Uploader;

var FileUploadLabel = _styledComponents["default"].div.withConfig({
  displayName: "components__FileUploadLabel",
  componentId: "xbcsjd-1"
})(["text-align:center;margin-bottom:10px;font-size:80%;color:#8c949d;"]);

exports.FileUploadLabel = FileUploadLabel;

var Container = _styledComponents["default"].div.withConfig({
  displayName: "components__Container",
  componentId: "xbcsjd-2"
})(["background:#fff;position:relative;display:flex;align-items:center;justify-content:center;flex-direction:column;margin:10px auto;transition:all 0.3s ease-in;"]);

exports.Container = Container;

var ErrorsContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__ErrorsContainer",
  componentId: "xbcsjd-3"
})(["max-width:300px;font-size:12px;color:red;text-align:left;"]);

exports.ErrorsContainer = ErrorsContainer;

var DeleteImage = _styledComponents["default"].div.withConfig({
  displayName: "components__DeleteImage",
  componentId: "xbcsjd-4"
})(["position:absolute;font-family:'helvetica',sans-serif;font-weight:lighter;top:5px;right:5px;font-size:18px;color:#fff;background:rgba(0,0,0,0.45);border-radius:6px;width:30px;height:30px;text-align:center;line-height:30px;cursor:pointer;"]);

exports.DeleteImage = DeleteImage;

var RemoveHeading = _styledComponents["default"].div.withConfig({
  displayName: "components__RemoveHeading",
  componentId: "xbcsjd-5"
})(["font-family:'helvetica',sans-serif;font-size:18px;font-weight:bold;color:#1e2834;margin-bottom:20px;"]);

exports.RemoveHeading = RemoveHeading;

var DeleteImageOverlay = _styledComponents["default"].div.withConfig({
  displayName: "components__DeleteImageOverlay",
  componentId: "xbcsjd-6"
})(["background:rgba(255,255,255,0.6);padding:30px;text-align:center;height:inherit;"]);

exports.DeleteImageOverlay = DeleteImageOverlay;

var UploadPictureContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__UploadPictureContainer",
  componentId: "xbcsjd-7"
})(["margin:2%;width:400px;height:240px;border:1px solid #cbd6e3;position:relative;display:block;background:", ";background-size:cover;@media (max-width:850px){width:100%;height:200px;}"], function (props) {
  return props.img ? "url(".concat(props.img, ") no-repeat 50% 50%") : "#f6f7f9";
});

exports.UploadPictureContainer = UploadPictureContainer;

var UploadPicturesWrapper = _styledComponents["default"].div.withConfig({
  displayName: "components__UploadPicturesWrapper",
  componentId: "xbcsjd-8"
})(["display:flex;align-items:center;justify-content:center;flex-wrap:wrap;width:100%;max-width:850px;img{width:90%;}"]);

exports.UploadPicturesWrapper = UploadPicturesWrapper;

var PreviewContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__PreviewContainer",
  componentId: "xbcsjd-9"
})(["display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex;justify-content:center;@media (max-width:850px){display:block;}"]);

exports.PreviewContainer = PreviewContainer;

var DropzoneContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__DropzoneContainer",
  componentId: "xbcsjd-10"
})(["margin-bottom:5px;> div{display:block;padding:10px 20px;text-align:center;border:1px dashed gray;border-radius:3px;}p{margin:0;}"]);

exports.DropzoneContainer = DropzoneContainer;
import React from 'react';
import styled from 'styled-components';

const ErrorMessageWrapper = styled.p`
  display: flex;
  padding-left: 5px;
  text-align: left;
`;

const ErrorMessage = ({ error, message }) => {
  if (message || error) {
    return (
      <ErrorMessageWrapper>
        <span role="img" aria-label="error icon">
          ⚠️️
        </span>
        {message || error.message}
      </ErrorMessageWrapper>
    );
  }
  return <div />;
};

export default ErrorMessage;

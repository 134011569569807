import Footer from './footer';
import Header from './header';
import LoadingIndicator from './loadingIndicator';
import Notification from './Notification';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from '../redux';
import styled, { injectGlobal } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import GlobalStyles from './globalStyle';

injectGlobal`
  ${GlobalStyles}
`;

const LayoutContainer = styled.div`
  min-height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
`;

class MainLayout extends PureComponent {
  render() {
    const { children, participant, meta, loading } = this.props;
    const { layout, status } = participant;

    return (
      <LayoutContainer>
        <ModalProvider>
          <Header
            enrollmentLogOut={this.props.logoutUser}
            meta={meta}
            status={status}
            study_status={meta.status}
            layout={layout}
          />
          <Notification />
          {loading ? <LoadingIndicator /> : children}
          <Footer {...meta} />
        </ModalProvider>
      </LayoutContainer>
    );
  }
}

export default connect(
  ({ participant, meta }) => ({
    participant,
    meta,
  }),
  dispatch => ({
    logoutUser: user => dispatch(actions.logoutUser(user)),
  }),
)(MainLayout);

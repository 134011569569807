import React, { PureComponent } from 'react';

import _ from 'lodash';
import { actions } from '../redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Banner = styled.div`
  width: 100%;
  background: #f2f5f6;
  padding: 15px 30px 0 30px;

  @media (min-width: 700px) {
    z-index: 1;
    width: 100%;
  }

  // to be re-designed
  &:after {
    content: '';
    margin: 0 auto;
    width: 100%;
    border-bottom: 1px solid #d9d8d9;
    padding-top: 5px;
  }

  p {
    margin: 0;
  }
`;

const BannerText = styled.div`
  width: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
`;

const BannerClose = styled.a`
  float: right;
  filter: brightness(0.6);
  width: 4%;
  margin: 0 auto;
  font-size: 14px;
`;

const messageFadeInterval = 8000;
const messageShowInterval = 1000;
const closeImageBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAXZJREFUSA3FljFygzAQRREzmRRwCs7AJUzjSZGjpElqV56cJEWGBi5BTweXgMKTgugzXrIoCHbxOFGz0mr3P0srCwUBa1VVPdR1/VoUxSNzq7rIhQa0eGJIA0zEcfxhjDklSfK5B4Yc5EIDWhxmACKI7R4JbG3ZNM1TlmUX5vN2CWIDDiwo77ruOU3TL+OBUKwI5oGQxggLoyh6sR6+EgqAPWxt4wYEGkcwwrZt3+2ghMfTvDABBJIlGGONpAm8ZtqcEQSsJhHx2FJreOHh5m1W3wmECCnsqiaGIH4GgkMIQ6ivzVZCQb9AmLgBtgiB5iJoJ8wLWQUpYasQaE13HQb3bP+7dX9yGG6A0M4v1mu2dUII3Yv7/rBSCO47/PRdV5AGQh9CbY7RJlAhYDW5Bg8JfOO5gNNfLC7FSGDDMLyFfd+fbVJOiY5dhSAWW3mtGx0SRyLIwRhPnefdsAnhip6V/TxOKNiBqSCk4cAmCM1PFrB7PSC/AVLBUM5PNYXBAAAAAElFTkSuQmCC';

export class Notification extends PureComponent {
  state = { messages: [], hidden: false };

  // uses pull model to manage messages
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newMessages = nextProps.participant.messages || [];

    if (!_.isEqual(this.props.participant.messages, newMessages)) {
      this.setState({ messages: newMessages }, () => {
        this.startTimeout(); // setTimeout won't last after refresh
      });
    }
  }

  startTimeout = () => setTimeout(this.onClearMessage, messageFadeInterval);

  clearTimeouts = () => {
    let timeoutId = setTimeout(null, 0);
    while (timeoutId--) clearTimeout(timeoutId);
  };

  // recursively clear and display new message
  onClearMessage = () => {
    if (this.state.messages.length === 0) return;

    this.clearTimeouts();

    // first-in-first-out for messages
    this.setState(
      { messages: _.drop(this.state.messages), hidden: true },
      () => {
        const { participant } = this.props;

        this.props.deleteOldestMessage(
          participant.enrollment_identifier,
          participant.participant_auth_token,
        );

        // wait and show another message
        setTimeout(() => {
          this.setState({ hidden: false });
          setTimeout(this.onClearMessage, messageFadeInterval);
        }, messageShowInterval);
      },
    );
  };

  parseMessage = message => {
    return _.isString(message) ? message : _.values(message)[0];
  };

  render() {
    const { messages, hidden } = this.state;

    if (messages.length === 0 || hidden) return null;

    return (
      <Banner onMouseOver={this.clearTimeouts} onMouseOut={this.startTimeout}>
        <BannerText>
          {this.parseMessage(messages[0])}
          <BannerClose
            id="t-banner_close"
            onClick={() => this.onClearMessage()}
          >
            <img
              src={closeImageBase64}
              alt={`Close message`}
              width="15"
              height="15"
            />
          </BannerClose>
        </BannerText>
      </Banner>
    );
  }
}

export default connect(
  ({ participant }) => ({ participant }),
  dispatch => ({
    deleteOldestMessage: (enrollment_identifier, participant_auth_token) =>
      dispatch(
        actions.deleteOldestMessage(
          enrollment_identifier,
          participant_auth_token,
        ),
      ),
  }),
)(Notification);

import CuriousPanda, {
  BillboardNavigation as BBN,
  GetFieldDictionary as GFD,
} from '@evidation/curious-panda';

import React from 'react';

export const BillboardNavigation = BBN;
export const GetFieldDictionary = GFD;

const LocalCuriousPanda = props => {
  return <CuriousPanda autoComplete="off" {...props} />;
};

export default LocalCuriousPanda;

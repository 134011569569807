import {
  COOKIE_ACM_ID,
  COOKIE_PARTICIPANT_AUTH,
  COOKIE_PORTAL_EMAIL,
  EMAIL_REGEX,
  ENROLLMENT_NODE_STATUSES_DISPLAYABLE,
  ENROLLMENT_STATUSES_NON_LOGINABLE,
  NON_LOGINABLE_LAYOUTS,
  NON_LOGOUTABLE_LAYOUTS,
  URL_REGEX,
} from '../constants';

import _ from 'lodash';
import api from '../services';

export const storeEnrollmentLocalStorage = (payload = {}) => {
  const {
    status,
    connected_apps,
    email,
    enrollment_identifier,
    tags,
    participant_auth_token,
    phone_number,
  } = payload;
  api.localStorage.set({
    status,
    connected_apps,
    email,
    enrollment_identifier,
    tags,
    participant_auth_token,
    phone_number,
  });
  api.cookie.set(COOKIE_PARTICIPANT_AUTH, participant_auth_token, {
    domain: 'myachievement.com',
  });
  return payload;
};

export const isValidUrl = url => URL_REGEX.test(url); // false with '', undefined and null

export const isValidEmail = email =>
  EMAIL_REGEX.test(String(email).toLowerCase());

export const getQueryString = (field, url = window.location.href) => {
  const reg = new RegExp(`[?&]` + field + `=([^&#]*)`, `i`);
  let string = reg.exec(url);
  return string ? string[1] : ``;
};

// use lodash instead of RegExp for IE support
export const pathContains = (path, candidates) => {
  return _.some(_.map(candidates, c => _.includes(path, c)));
};

// String#slice and String#indexOf work fine
export const getUrlParams = (search = ``) => {
  if (_.isEmpty(search)) {
    return {};
  }

  const query = search.slice(search.indexOf('?') + 1);
  let hashes = _.split(query, '&');

  return _.reduce(
    hashes,
    (acc, hash) => {
      let [key, val] = hash.split('=');
      return { ...acc, [key]: decodeURIComponent(val) };
    },
    {},
  );
};

export const formatPhone = phoneNum => {
  var regexObj = /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (regexObj.test(phoneNum)) {
    var parts = phoneNum.match(regexObj);
    var phone = '';
    if (parts[1]) {
      phone += '+1 (' + parts[1] + ') ';
    }
    phone += parts[2] + '-' + parts[3];
    return phone;
  }
  // otherwise invalid phone number
  return phoneNum;
};

export const nextNode = (nodes = []) =>
  _.find(nodes, obj =>
    _.includes(ENROLLMENT_NODE_STATUSES_DISPLAYABLE, obj.status),
  ) || {};

//
// Achievement Login

export const getAcmEmail = () =>
  api.cookie.get(COOKIE_PORTAL_EMAIL) || getQueryString(`email`);

export const getAcmCookie = () => api.cookie.get(COOKIE_ACM_ID);

export const canLogin = (status, layout, email, hasAcmCookie, is_program) => {
  if (is_program) return false;
  if (hasAcmCookie && !email) return true;

  return (
    !_.includes(ENROLLMENT_STATUSES_NON_LOGINABLE, status) &&
    !_.includes(NON_LOGINABLE_LAYOUTS, layout) &&
    !hasAcmCookie
  );
};

//
// Login & Logout

export const isEnrolled = (status, layout) => {
  switch (status) {
    case `study_completed`:
    case `enrollment_completed`:
      return true;

    case `pending`:
    case `timing_out`:
    case `withdrawing`:
    case `disqualifying`:
      return layout === `dashboard`;

    default:
      return false;
  }
};

export const canLogout = (
  loginable,
  enrolled,
  portalEmail,
  hasAcmCookie,
  layout,
  is_program,
) => {
  return (
    !is_program &&
    !loginable &&
    !_.includes(NON_LOGOUTABLE_LAYOUTS, layout) &&
    (enrolled || (portalEmail && hasAcmCookie))
  );
};

export const curiousPandaParticipantProp = ({
  enrollment_identifier,
  participant_auth_token,
  auth_token,
  nodes = [{}],
}) => {
  const [{ slug }] = nodes;
  return {
    enrollment_identifier,
    participant_auth_token,
    auth_token,
    node_slug: slug,
  };
};

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.number.to-fixed");

require("core-js/modules/es.regexp.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.repeat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateDecimal = exports.validateMinMax = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var validateMinMax = function validateMinMax(min, max, decimal) {
  return function (value, allValues, props, name) {
    if (value && validInputs(min) && validInputs(max) && validInputs(decimal)) {
      var val = _lodash["default"].toNumber(value);

      if (min <= val && max >= val) {
        return undefined;
      } else {
        return "Please respond with an number between ".concat(min.toFixed(decimal), " - ").concat(max.toFixed(decimal), ".");
      }
    }
  };
};

exports.validateMinMax = validateMinMax;

var validateDecimal = function validateDecimal(decimal) {
  return function (value, allValues, props, name) {
    if (value && validInputs(decimal)) {
      var pattern = decimal > 0 ? "^-?\\d+\\.\\d{".concat(decimal, "}$") : "^-?\\d+$";
      var regex = new RegExp(pattern, 'g');

      if (regex.test(value)) {
        return undefined;
      } else {
        return "Please respond with a number containing ".concat(decimal, " decimal places (Ex.: X").concat(decimal > 0 ? "." + "X".repeat(decimal) : "", ")");
      }
    }
  };
};

exports.validateDecimal = validateDecimal;

var validInputs = function validInputs(v) {
  return !_lodash["default"].isNaN(v) && _lodash["default"].isNumber(v);
};

var validations = function validations(_ref) {
  var minimum = _ref.minimum,
      maximum = _ref.maximum,
      decimal = _ref.decimal;
  return [validateMinMax(minimum, maximum, decimal), validateDecimal(decimal)];
};

exports.validations = validations;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var states = [{
  value: 'AL',
  label: 'Alabama'
}, {
  value: 'AK',
  label: 'Alaska'
}, {
  value: 'AZ',
  label: 'Arizona'
}, {
  value: 'AR',
  label: 'Arkansas'
}, {
  value: 'CA',
  label: 'California'
}, {
  value: 'CO',
  label: 'Colorado'
}, {
  value: 'CT',
  label: 'Connecticut'
}, {
  value: 'DC',
  label: 'District of Columbia'
}, {
  value: 'DE',
  label: 'Delaware'
}, {
  value: 'FL',
  label: 'Florida'
}, {
  value: 'GA',
  label: 'Georgia'
}, {
  value: 'HI',
  label: 'Hawaii'
}, {
  value: 'ID',
  label: 'Idaho'
}, {
  value: 'IL',
  label: 'Illinois'
}, {
  value: 'IN',
  label: 'Indiana'
}, {
  value: 'IA',
  label: 'Iowa'
}, {
  value: 'KS',
  label: 'Kansas'
}, {
  value: 'KY',
  label: 'Kentucky'
}, {
  value: 'LA',
  label: 'Louisiana'
}, {
  value: 'ME',
  label: 'Maine'
}, {
  value: 'MD',
  label: 'Maryland'
}, {
  value: 'MA',
  label: 'Massachusetts'
}, {
  value: 'MI',
  label: 'Michigan'
}, {
  value: 'MN',
  label: 'Minnesota'
}, {
  value: 'MS',
  label: 'Mississippi'
}, {
  value: 'MO',
  label: 'Missouri'
}, {
  value: 'MT',
  label: 'Montana'
}, {
  value: 'NE',
  label: 'Nebraska'
}, {
  value: 'NV',
  label: 'Nevada'
}, {
  value: 'NH',
  label: 'New Hampshire'
}, {
  value: 'NJ',
  label: 'New Jersey'
}, {
  value: 'NM',
  label: 'New Mexico'
}, {
  value: 'NY',
  label: 'New York'
}, {
  value: 'NC',
  label: 'North Carolina'
}, {
  value: 'ND',
  label: 'North Dakota'
}, {
  value: 'OH',
  label: 'Ohio'
}, {
  value: 'OK',
  label: 'Oklahoma'
}, {
  value: 'OR',
  label: 'Oregon'
}, {
  value: 'PA',
  label: 'Pennsylvania'
}, {
  value: 'RI',
  label: 'Rhode Island'
}, {
  value: 'SC',
  label: 'South Carolina'
}, {
  value: 'SD',
  label: 'South Dakota'
}, {
  value: 'TN',
  label: 'Tennessee'
}, {
  value: 'TX',
  label: 'Texas'
}, {
  value: 'UT',
  label: 'Utah'
}, {
  value: 'VT',
  label: 'Vermont'
}, {
  value: 'VA',
  label: 'Virginia'
}, {
  value: 'WA',
  label: 'Washington'
}, {
  value: 'WV',
  label: 'West Virginia'
}, {
  value: 'WI',
  label: 'Wisconsin'
}, // n: Commonwealth/Territory:	Abbreviation
{
  value: 'AS',
  label: 'American Samoa'
}, {
  value: 'DC',
  label: 'District of Columbia'
}, {
  value: 'FM',
  label: 'Federated States of Micronesia'
}, {
  value: 'GU',
  label: 'Guam'
}, {
  value: 'MH',
  label: 'Marshall Islands'
}, {
  value: 'MP',
  label: 'Northern Mariana Islands'
}, {
  value: 'PW',
  label: 'Palau'
}, {
  value: 'PR',
  label: 'Puerto Rico'
}, {
  value: 'VI',
  label: 'Virgin Islands'
}, // n: Military "State":	Abbreviation
{
  value: 'AE',
  label: 'Armed Forces Africa'
}, {
  value: 'AA',
  label: 'Armed Forces Americas'
}, {
  value: 'AE',
  label: 'Armed Forces Canada'
}, {
  value: 'AE',
  label: 'Armed Forces Europe'
}, {
  value: 'AE',
  label: 'Armed Forces Middle East'
}, {
  value: 'AP',
  label: 'Armed Forces Pacific'
}, {
  value: 'WY',
  label: 'Wyoming'
}];
var _default = states;
exports["default"] = _default;
"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeHeaderContainer = exports.GroupListHolder = exports.PadHolder = exports.RadioCheckbox = exports.CustomInputStyles = exports.RemoveButton = exports.InnerButton = exports.AddButton = exports.InnerLi = exports.Li = exports.OuterUl = exports.InnerUl = void 0;

var _formElements = require("@evidation/form-elements");

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var inputSelectedColor = "#05456d";

var InnerUl = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__InnerUl",
  componentId: "sc-1rugkys-0"
})(["list-style:none;margin:0;padding:0;li{.draggable{display:none;&.isDraggable{display:unset;}}}"]);

exports.InnerUl = InnerUl;

var OuterUl = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__OuterUl",
  componentId: "sc-1rugkys-1"
})(["list-style:none;margin:0;padding:0;"]);

exports.OuterUl = OuterUl;

var Li = _styledComponents["default"].li.withConfig({
  displayName: "commonComponents__Li",
  componentId: "sc-1rugkys-2"
})(["position:relative;margin:0;padding:0;"]);

exports.Li = Li;

var InnerLi = _styledComponents["default"].li.withConfig({
  displayName: "commonComponents__InnerLi",
  componentId: "sc-1rugkys-3"
})(["position:relative;display:grid;grid-template-columns:auto 30px;grid-template-rows:auto;padding:5px 0 0 5px;background-color:transparent;margin-bottom:8px;&:hover{background-color:rgba(12,39,48,0.25);}"]);

exports.InnerLi = InnerLi;
var AddButton = (0, _styledComponents["default"])(_formElements.Button).withConfig({
  displayName: "commonComponents__AddButton",
  componentId: "sc-1rugkys-4"
})(["font-size:10px;width:70px;cursor:pointer;padding:4px 0;margin:10px 0;"]);
exports.AddButton = AddButton;

var InnerButton = _styledComponents["default"].button.withConfig({
  displayName: "commonComponents__InnerButton",
  componentId: "sc-1rugkys-5"
})(["grid-column:2;grid-row:1;background:none;border:0;margin:0;border-radius:3px;cursor:pointer;&:hover{background:linear-gradient(-180deg,#28b90c 0%,#27b30b 100%);}&:disabled{cursor:default;&:hover{background:inherit;}}"]);

exports.InnerButton = InnerButton;

var RemoveButton = _styledComponents["default"].button.withConfig({
  displayName: "commonComponents__RemoveButton",
  componentId: "sc-1rugkys-6"
})(["background:none;border:0;position:absolute;top:20px;right:10px;line-height:1em;color:#0c4e69;cursor:pointer;vertical-align:middle;&:hover{text-decoration:none;}"]);

exports.RemoveButton = RemoveButton;
var CustomInputStyles = (0, _styledComponents.css)(["input[type='checkbox'],input[type='radio']{position:absolute;opacity:0;+ label{position:relative;padding-left:28px;cursor:pointer;line-height:20px;display:inline-block;font-size:medium;&:before{content:'';position:absolute;border:1px solid #ddd;background:#fff;box-sizing:border-box;}&:after{content:'';position:absolute;box-sizing:border-box;transition:all 0.2s ease;}}&:disabled + label{cursor:default;}}input[type='checkbox']{+ label{&:before{left:4px;top:5px;width:16px;height:16px;border-radius:3px;}&:after{left:9px;top:6px;width:6px;height:10px;border:solid ", ";border-width:0 2px 2px 0;transform:rotate(45deg);}}&:not(:checked) + label:after{transform:rotate(45deg) scale(0);}&:checked + label:after{transform:rotate(45deg) scale(1);}}input[type='radio']{+ label{&:before{left:4px;top:5px;width:16px;height:16px;border-radius:50px;background:linear-gradient(to bottom,#ffffff 0%,#e8e9e8 98%);}&:after{left:8px;top:9px;width:8px;height:8px;background:", ";border-radius:50px;}}&:checked + label:before{background:#fdfdfd;}&:not(:checked) + label:after{transform:scale(0);}&:checked + label:after{transform:scale(1);}}label p{font-size:1rem;line-height:1.2;padding-top:1px;}"], inputSelectedColor, inputSelectedColor);
exports.CustomInputStyles = CustomInputStyles;

var RadioCheckbox = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__RadioCheckbox",
  componentId: "sc-1rugkys-7"
})(["text-align:", ";display:", ";", ";"], function (props) {
  return props.split ? "center" : "normal";
}, function (props) {
  return props.split ? "inline-block" : "inherit";
}, function (props) {
  return props.split && "width: ".concat(props.split ? "".concat(100 / props.options.length, "%") : "100%");
});

exports.RadioCheckbox = RadioCheckbox;

var PadHolder = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__PadHolder",
  componentId: "sc-1rugkys-8"
})(["position:relative;width:100%;height:250px;background-color:#fff;box-shadow:0 1px 4px rgba(0,0,0,0.27),0 0 40px rgba(0,0,0,0.08) inset;border-radius:4px;& canvas{position:absolute;left:0;right:0;bottom:0;top:0;width:100%;height:100%;border-radius:4px;}"]);

exports.PadHolder = PadHolder;

var GroupListHolder = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__GroupListHolder",
  componentId: "sc-1rugkys-9"
})(["display:flex;justify-content:space-around;flex-direction:", ";.responsive-input-label{display:", ";}> div{display:flex;flex-direction:column;}@media only screen and (max-width:760px),(min-device-width:768px) and (max-device-width:1024px){flex-direction:column;.responsive-input-label{all:", ";font-size:14px;color:#395865;}> div{align-items:center;flex-direction:row;width:100%;text-align:left;}}"], function (props) {
  return props.displayRow ? "row" : "column";
}, function (props) {
  return props.allowResponsiveLabel ? "none" : "inherit";
}, function (props) {
  return props.allowResponsiveLabel ? "unset" : "inherit";
});

exports.GroupListHolder = GroupListHolder;
GroupListHolder.defaultProps = {
  allowResponsiveLabel: true,
  displayRow: false
};

var RangeHeaderContainer = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__RangeHeaderContainer",
  componentId: "sc-1rugkys-10"
})(["display:flex;padding:0 14px 0 12px !important;> div,> li{flex-grow:1;flex-basis:0;list-style:none;padding:0 3px;text-align:center;}"]);

exports.RangeHeaderContainer = RangeHeaderContainer;
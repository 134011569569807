import Close from './close';
import { MarkdownHtml as Markdown } from '@evidation/form-elements';
import Modal from 'styled-react-modal';
import React from 'react';
import styled from 'styled-components';

const StyledModal = Modal.styled`
    background-color: white;
    opacity: ${props => props.opacity};
    transition: all ease 200ms;
    min-width: 420px;
    max-height: 100%
    overflow: auto;

    @media (max-width: 450px) {
      min-width: 100%;
      width: 100%;
    }

    @media (min-width: 850px) {
      min-width: 500px;
    }
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  padding-left: 40px;
  padding-bottom: 5px;

  h2 {
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    padding: 30px 10px 0 30px;
    h2 {
      font-size: 20px;
    }
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin-right: 5px;
  transition: all 0.3 ease-out;

  @media (min-width: 800px) {
    padding: 10px;
    margin-right: -12px;
  }

  :hover {
    svg {
      opacity: 0.8;
    }
  }
`;

const Content = styled.div`
  font-weight: 300;
  background: #fff;
  max-width: 500px;
  padding: 40px;
  padding-top: 0px;
  @media (max-width: 400px) {
    padding: 30px;
    padding-top: 0;
  }
`;

const Info = styled.div`
  p {
    color: #5b6b7d;
    text-align: center;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const MainSection = styled.div`
  p {
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
`;

const BasicStyledModal = ({
  isOpen,
  afterOpen,
  beforeClose,
  closeModal,
  opacity,
  children,
  title,
  content,
  afterClose,
  header,
}) => (
  <React.Fragment>
    <StyledModal
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
      opacity={opacity}
      afterClose={afterClose}
    >
      <TitleDiv>
        <h2>{header}</h2>
        <CloseButton onClick={closeModal}>
          <Close />
        </CloseButton>
      </TitleDiv>
      <Content>
        <Info>
          <Markdown>{content}</Markdown>
        </Info>
        <MainSection>{children}</MainSection>
      </Content>
    </StyledModal>
  </React.Fragment>
);

export default BasicStyledModal;

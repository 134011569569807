import styled, { css } from 'styled-components';

import { Button } from '@evidation/form-elements';
import { NavHashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import React from 'react';
import LogoAcm from './logo';

const colors = css`
  background: #0652b6;
  color: #fff;
  transition: background 0.2s ease-out;
  :hover {
    background: #0c4384;
    cursor: pointer !important;
  }
`;

export const BigBlueButton = styled(Button)`
  height: 50px;
  padding: 0 30px;
  text-align: center;
  white-space: nowrap;

  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 29px;
  border-radius: 5px;
  :hover {
    cursor: pointer !important;
  }

  ${colors};
  ${p => (p.hero ? `:disabled {${colors}}` : ``)};
`;

export const mobileOnly = css`
  @media (min-width: 801px) {
    display: none !important;
  }
`;

export const NavLinkStyle = css`
  color: #2a3f53;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  align-self: center;
  padding: ${p => (p.padding ? p.padding : '10px 15px')};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.5;

  &:hover,
  &.active {
    opacity: 1;
    color: #2a3f53;
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${NavLinkStyle};
`;

const StyledNavHashLink = styled(NavHashLink)`
  ${NavLinkStyle};
`;

const NavLinkDiv = styled.div`
  ${NavLinkStyle};
`;

const LogoNavLink = styled(StyledNavLink)`
  display: flex;
  opacity: 1;
  padding-top: 0;
  padding-bottom: 0;
`;

export const Logo = ({ style = {} }) => (
  <LogoAcm id="t-achievementLogo" alt={`Achievement Logo`} style={style} />
);

export const LogoLink = ({ to, onClick, id, ...logoProps }) => (
  <LogoNavLink to={to} onClick={onClick} id={id}>
    <Logo {...logoProps} />
  </LogoNavLink>
);

export const ContactUsLink = ({ padding, onClick }) => (
  <NavLinkDiv onClick={onClick} padding={padding} id="t-contactUs">
    CONTACT US
  </NavLinkDiv>
);

export const DashboardLink = ({ onClick }) => (
  <StyledNavLink id="t-dashboard" to="/manage/dashboard" onClick={onClick}>
    DASHBOARD
  </StyledNavLink>
);

export const SettingsLink = ({ onClick }) => (
  <StyledNavLink id="t-settings" to="/manage/settings" onClick={onClick}>
    SETTINGS
  </StyledNavLink>
);

export const FaqLink = ({ onClick }) => (
  <StyledNavHashLink
    smooth
    to="#faq"
    id="t-faq"
    onClick={onClick}
    isActive={(match, location) => location.hash === `#faq`}
  >
    FAQ
  </StyledNavHashLink>
);

export const LogInLink = ({ onClick }) => (
  <StyledNavLink to="/log-in" id="t-logIn" onClick={onClick}>
    LOG IN
  </StyledNavLink>
);

export const LogOutLink = ({ onClick }) => (
  <StyledNavLink onClick={onClick} id="t-logOut" to="#">
    LOG OUT
  </StyledNavLink>
);

import styled, { css } from 'styled-components';

export const CommonSpacer = props => css`
  ${props => props.m && `margin: ${props.m}px`};
  ${props => props.p && `padding: ${props.p}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px;`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ta && `text-align: ${props.ta}`};
`;

export const Content = styled.div`
  flex: 0 auto;
`;

export const Container = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  width: auto;

  &:after {
    content: ' ';
    display: block;
    clear: both;
  }

  ${CommonSpacer};

  bold,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }
`;

export const EnrollmentContent = styled.div`
  min-height: 500px;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  float: none;
  margin: auto;
  max-width: 600px;

  ${CommonSpacer};
`;

export const H2 = styled.h2`
  color: #2a3f53;
  font-family: 'Adamina', serif;
  font-size: 30px;
  font-weight: 400;
  margin: 0;

  ${CommonSpacer};
`;

export const P = styled.p`
  font-size: 16px;

  ${CommonSpacer};
`;

export const Div = P;

export const BottomBorder = styled.div`
  border-bottom: 1px solid #eff2f4;
`;

export const Spacer = styled.div`
  ${CommonSpacer};
  padding: ${p => p.padding};
`;

export const Divider = styled.div`
  display: block;
  height: 1px;
  background-color: #7d7e7f;
  margin-top: 24px;
  margin-bottom: 24px;
`;

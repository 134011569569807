import React from 'react';
import BasicPageWrapper from './basicPageWrapper';
import Button from '../components/button';
import ContactModal from '../components/contactModal';

const Error = () => (
  <BasicPageWrapper title={'An error occurred'}>
    <div>
      <p>
        Hi there, it looks like something went wrong on our end. Please refresh
        your page to try again.
      </p>
      <p>If the problem persists, please contact support.</p>
      <ContactModal>
        {({ showModal }) => (
          <Button
            onClick={showModal}
            secondary
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Contact Us
          </Button>
        )}
      </ContactModal>
    </div>
  </BasicPageWrapper>
);

Error.defaultProps = {
  error: {},
};

export default Error;

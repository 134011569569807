import {
  ACHIEVEMENT_ENDPOINT,
  STUDY_ID,
  TRIAGE_API_VERSION,
  TRIAGE_ENDPOINT,
} from '../constants';
import { get, getJSON, remove, set } from 'js-cookie';
import axios from 'axios';
import { getQueryString } from '../utils';
import { logApiError } from '../utils/errorLogger';

const cookieExpiry = 7;
export const CookieName = `${STUDY_ID}_`;
const PreviewTokenCookieName = `${CookieName}preview_token`;

export const triage = axios.create({
  baseURL: `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/`,
});

export const triagev3 = axios.create({
  baseURL: `${TRIAGE_ENDPOINT}/api/v3/`,
});

export const portal = axios.create({
  baseURL: `${ACHIEVEMENT_ENDPOINT}/api/v4/`,
});

export const getPreviewToken = () => {
  // getting preview token from cookies
  let previewToken = getJSON(PreviewTokenCookieName) || ``;

  // opt for preview token in url, if it exists we set it to the cookie
  if (getQueryString(`preview_token`) !== ``) {
    previewToken = getQueryString(`preview_token`);
    set(PreviewTokenCookieName, previewToken, { expires: cookieExpiry });
  }
  return previewToken;
};

const config = ({ auth_token = `` }) => ({
  headers: {
    'Participant-Auth-Token': auth_token,
    'Preview-Token': getPreviewToken(),
  },
});

const api = {
  meta: () => triage({ method: `GET`, url: `/meta`, ...config({}) }),
  triage: {
    delete: (url, data, auth_token) =>
      triage({ method: `DELETE`, url, ...config({ auth_token }) }),
    get: (url, auth_token) =>
      triage({ method: `GET`, url, ...config({ auth_token }) }),
    patch: (url, data, auth_token) =>
      triage({ method: `PATCH`, url, data, ...config({ auth_token }) }),
    post: (url, data, auth_token) =>
      triage({ method: `POST`, url, data, ...config({ auth_token }) }),
    put: (url, data, auth_token) =>
      triage({ method: `PUT`, url, data, ...config({ auth_token }) }),
    process_contribution: (url, data, auth_token) =>
      triage({ method: `PUT`, url, data, ...config({ auth_token }) }),
    begin: (auth_token, enrollment_identifier, node_slug, data = {}) =>
      triage({
        method: `PUT`,
        url: `/enrollments/${enrollment_identifier}/nodes/${node_slug}/begin`,
        data: {},
        ...config({ auth_token }),
      }),
    verify_email: (auth_token, enrollment_identifier) =>
      triage({
        method: `PUT`,
        url: `/enrollments/${enrollment_identifier}/verify_email`,
        ...config({ auth_token }),
      }),
    send_token: (auth_token, enrollment_identifier, data) =>
      triagev3({
        method: `POST`,
        url: `/enrollments/${enrollment_identifier}/send_token`,
        data,
        ...config({ auth_token }),
      }),
    verify_token: (auth_token, enrollment_identifier, data) =>
      triagev3({
        method: `POST`,
        url: `/enrollments/${enrollment_identifier}/verify_token`,
        data,
        ...config({ auth_token }),
      }),
    // need to provide a random number so that iOS browser won't think it's the same url and returns cached response
    get_upload_url: (
      auth_token,
      enrollment_identifier,
      key,
      file_ext,
      bucket,
      rand,
    ) =>
      triage({
        method: `GET`,
        url: `/enrollments/${enrollment_identifier}/upload_secure?graph_id=${key}&file_ext=${file_ext}&bucket=${bucket}&rand=${rand}`,
        ...config({ auth_token }),
      }),
  },
  portal: {
    pw_score: password =>
      portal({ method: `POST`, url: `/passwords/score`, data: { password } }),
  },
  external: {
    takeSurvey: (enrollment_identifier, url) =>
      window.open(
        `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/enrollments/${enrollment_identifier}${url}`,
        `_blank`,
      ),
    getInformedConsent: ({
      enrollment_identifier,
      slug,
      participant_auth_token,
    }) =>
      triagev3({
        method: `GET`,
        url: `/studies/${STUDY_ID}/enrollments/${enrollment_identifier}/informed_consent_url?node_identifier=${slug}`,
        ...config({ auth_token: participant_auth_token }),
      }),
  },
  localStorage: {
    get: () => getJSON(CookieName),
    set: d => set(CookieName, d, { expires: cookieExpiry }),
    remove: () => remove(CookieName),
  },
  cookie: {
    get,
    remove,
    set,
  },
};

triage.interceptors.response.use(
  res => res,
  error => {
    if (error.response) {
      const { message } = logApiError({
        response: error.response,
        request: error.request,
      });

      return Promise.reject({ ...error, error: { message } });
    } else return Promise.reject(error);
  },
);

export default api;

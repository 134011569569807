import _ from 'lodash';

export const meta = (state = {}, { type, payload = {} }) => {
  switch (type) {
    case 'META/RECEIVE':
      return { ...state, ...payload };
    case 'META/FAILURE':
      return { failure: true };
    case 'LANDING/RECEIVE':
      return { ...state, ..._.omit(payload, ['nodes', 'layout']) };
    default:
      return state;
  }
};

"use strict";

require("core-js/modules/es.array.slice");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseMaskedType = exports.parsePhone = void 0;

var parsePhone = function parsePhone(number) {
  return number ? number.replace(/-|\(|\)|\s|/g, '').slice(0, 10) : '';
};

exports.parsePhone = parsePhone;

var parseMaskedType = function parseMaskedType(v) {
  return v ? v.replace(/_/g, '') : undefined;
};

exports.parseMaskedType = parseMaskedType;
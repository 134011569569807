import {
  BigBlueButton,
  ContactUsLink,
  DashboardLink,
  FaqLink,
  LogInLink,
  LogOutLink,
  LogoLink,
  SettingsLink,
  StyledNavLink,
  mobileOnly,
} from './headerLinks';
import React, { Component } from 'react';
import { canLogin, canLogout, isEnrolled } from '../utils';
import { STUDY_STATUSES_ENROLLMENT_CLOSED } from '../constants';
import CheckEligibilityModal from '../../src/routes/education/components/checkEligibilityModal';
import ContactModal from './contactModal';
import Hamburger from './hamburger';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';
import {
  LandingSidePadding,
  LandingPadding,
  LandingPageWidth,
} from '../routes/education/components';
import { Spacer } from '../components';
import Stickyfill from 'stickyfilljs';

const mobileMaxWidth = `800px`;
const desktopMinWidth = `801px`;

const HeaderButton = styled(BigBlueButton)`
  height: 30px;
  padding: 1px 20px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: 14px;
`;

const DesktopHeaderButton = styled(BigBlueButton)`
  height: 41px;
  padding: 7px 35px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: 16px;
`;

const logoLink = (layout = ``) => {
  switch (layout) {
    case `dashboard`:
      return `/faq`;
    case `education`:
    case `follow_up`:
    case `terminal_state`:
      return `/`;
    case `screener`:
    case `registration`:
    case `informed_consent`:
    default:
      return `/${layout}`;
  }
};

const StickyHeader = styled.header`
  flex: 0 0 auto;
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  border-bottom: 1px solid #e3e3e3;

  @media (max-width: ${mobileMaxWidth}) {
    height: 60px;
  }
`;

const HeaderContent = styled.div`
  max-width: ${LandingPageWidth};
  margin: auto;
  height: 100%;
  display: flex;
`;

export const desktopOnly = `
  @media (max-width: ${mobileMaxWidth}) {
    display: none !important;
  }
`;

const DesktopLinks = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  justify-content: space-between;

  & > * {
    padding: 0 10px;
  }
  & > :last-child {
    padding: 0;
  }
  ${desktopOnly};
`;

const MobileHeaderEligibilityMaxWidth = '340px';

const MobileHeaderEligibility = styled.div`
  ${mobileOnly};

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    display: none !important;
  }
`;

const MobileLinks = styled.div`
  ${mobileOnly};
  position: absolute;
  background: #fff;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  padding: ${LandingPadding};
  padding-top: 10px;
  border-bottom: 1px solid #eff2f4;

  & > * {
    align-self: auto;
    padding: 5px 0;
  }
  & > :first-child {
    padding-top: 0;
  }
  & > :last-child {
    padding-bottom: 15px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  @media (max-width: ${mobileMaxWidth}) {
    padding: 10px 0;
  }

  & > * {
    display: flex;
  }
  ${p =>
    p.noCheckEligibility &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        padding: 11px 0;
      }
    `}
`;

const Left = styled.div`
  display: flex;
`;

const Right = styled(Left)`
  display: flex;
  align-items: center;
  flex-flow: row;

  & > :first-child {
    padding-left: 0;
  }
  & > :last-child {
    padding: 0;
    padding-left: 15px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  @media (min-width: 500px) and (max-width: ${mobileMaxWidth}) {
    ${p => p.isEducation && `padding-left: 54px;`}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${p => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${p => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
  }

  @media (min-width: ${desktopMinWidth}) {
    & > * {
      padding-left: 0 !important;
      margin-right: auto;
    }

    & > #t-AchievementLogo {
      padding-bottom: 5px !important;
    }
  }

  ${p =>
    p.centered &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        flex: 1 auto;
        justify-content: center;
        margin-right: 30px;
      }
    `};
`;

const StudyTitle = styled.div`
  color: #2a3f53;
  font-family: Heebo;
  font-size: 16px;
  line-height: 24px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${p => (p.enrollmentIsClosed ? '70vw' : '50vw')};
  align-self: center;
`;

export class Header extends Component {
  // TODO: Move navigation state into redux
  state = { showMobileDropdown: false };
  myRef = React.createRef();

  componentDidMount() {
    if (this.myRef.current) {
      Stickyfill.add(this.myRef.current);
    }
  }

  toggleMobileNavOff = () => this.setState({ showMobileDropdown: false });
  toggleMobileNav = () =>
    this.setState({ showMobileDropdown: !this.state.showMobileDropdown });

  render() {
    const {
      enrollmentLogOut,
      status,
      study_status,
      layout,
      participant_layout,
      portalEmail,
      hasAcmCookie,
      hero_title,
      hero_content,
      hero_button = `CHECK ELIGIBILITY`,
      meta: { study_name, is_program = false },
    } = this.props;

    const enrolled = isEnrolled(status, participant_layout);

    const loginable = canLogin(
      status,
      participant_layout,
      portalEmail,
      hasAcmCookie,
      is_program,
    );

    const logoutable = canLogout(
      loginable,
      enrolled,
      portalEmail,
      hasAcmCookie,
      participant_layout,
      is_program,
    );

    const isLanding = this.props.location.pathname === `/`;
    const isEducation =
      layout === `education` && participant_layout !== 'dashboard' && isLanding;
    const enrollmentIsClosed = Object.values(
      STUDY_STATUSES_ENROLLMENT_CLOSED,
    ).includes(study_status);

    const LeftLinks = () =>
      enrolled && (
        <React.Fragment>
          <DashboardLink onClick={this.toggleMobileNavOff} />
          <SettingsLink onClick={this.toggleMobileNavOff} />
        </React.Fragment>
      );

    const RightLinks = () => (
      <React.Fragment>
        {isEducation && (
          <StyledNavLink
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              this.toggleMobileNavOff();
            }}
            isActive={(match, location) => match && location.hash === ``}
          >
            HOME
          </StyledNavLink>
        )}
        {isEducation && <FaqLink onClick={this.toggleMobileNavOff} />}
        <ContactModal onClose={this.toggleMobileNavOff}>
          {({ showModal }) => (
            <ContactUsLink
              onClick={showModal}
              padding={this.state.showMobileDropdown ? '0' : '15px'}
            />
          )}
        </ContactModal>
        {loginable && <LogInLink onClick={this.toggleMobileNavOff} />}
        {logoutable && <LogOutLink onClick={() => enrollmentLogOut()} />}
      </React.Fragment>
    );

    return (
      <StickyHeader sticky={isEducation} innerRef={this.myRef}>
        <Spacer
          pl={LandingSidePadding}
          pr={LandingSidePadding}
          style={{ height: '100%' }}
        >
          <HeaderContent>
            <NavContainer
              noCheckEligibility={enrollmentIsClosed || !isEducation}
            >
              <Hamburger
                onClick={this.toggleMobileNav}
                active={this.state.showMobileDropdown}
              />
              <TitleSection
                centered={enrollmentIsClosed || !isEducation}
                hasStudyTitle={study_name}
                isEducation={isEducation}
              >
                <LogoLink
                  to={logoLink(participant_layout)}
                  onClick={this.toggleMobileNavOff}
                  id="t-AchievementLogo"
                />
                <StudyTitle
                  id="t-studyTitle"
                  enrollmentIsClosed={enrollmentIsClosed}
                >
                  {study_name}
                </StudyTitle>
              </TitleSection>
              {isEducation && !enrollmentIsClosed && (
                <MobileHeaderEligibility>
                  <CheckEligibilityModal
                    title={hero_title}
                    content={hero_content}
                    render={({ onClick }) => (
                      <HeaderButton onClick={onClick}>START</HeaderButton>
                    )}
                  />
                </MobileHeaderEligibility>
              )}
              <DesktopLinks>
                <Left>
                  <LeftLinks />
                </Left>
                <Right>
                  <RightLinks />
                  {isEducation && !enrollmentIsClosed && (
                    <CheckEligibilityModal
                      title={hero_title}
                      content={hero_content}
                      buttonLabel={hero_button}
                      render={({ onClick }) => (
                        <DesktopHeaderButton onClick={onClick}>
                          {hero_button}
                        </DesktopHeaderButton>
                      )}
                    />
                  )}
                </Right>
              </DesktopLinks>
            </NavContainer>
          </HeaderContent>
        </Spacer>
        <MobileLinks visible={this.state.showMobileDropdown}>
          <LeftLinks />
          <RightLinks />
        </MobileLinks>
      </StickyHeader>
    );
  }
}

export default withRouter(
  connect(
    ({
      participant: { portalEmail, hasAcmCookie, layout: participant_layout },
      landing: { layout, nodes },
    }) => {
      const hero_props = {};
      if (!_.isEmpty(nodes)) {
        Object.assign(
          hero_props,
          _.pick(nodes[0].content, [
            'hero_title',
            'hero_content',
            'hero_button',
          ]),
        );
      }
      return {
        portalEmail,
        hasAcmCookie,
        layout,
        participant_layout,
        ...hero_props,
      };
    },
  )(Header),
);

import ContactModal from './contactModal';
import GoogleTagManager from './GoogleTagManager';
import { Logo } from './headerLinks';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FooterLink = styled.a`
  white-space: nowrap;
  text-decoration: none;
  padding: 0 10px;
  color: #2a3f53;
  :hover {
    opacity: 0.7;
    color: #2a3f53;
  }
`;

const StyledFooter = styled.footer`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  border-top: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 15px;
  min-height: 100px;
  @media (min-width: 375px) {
    white-space: nowrap;
  }

  color: #2a3f53;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  & > * {
    flex: 1;
    align-items: center;
  }

  @media (max-width: 940px) {
    flex-flow: column;
    & > * {
      padding: 10px;
    }
  }
`;

const FooterConstraints = styled.div`
  max-width: 1260px;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  display: flex;

  @media (max-width: 940px) {
    flex-flow: column;
    & > div:nth-child(-n + 2) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 440px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const HiddenTimestamp = styled.span`
  height: 0;
  width: 0;
  padding: 0;
  color: #fff;
  font-size: 8px;
`;

const Centered = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  justify-content: flex-start;
  text-align: center;

  :nth-of-type(3) {
    justify-content: flex-end;
  }

  @media (max-width: 940px) {
    justify-content: center;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 374px) {
    font-size: 11px;
  }
`;

const builtAt = process.env.REACT_APP_VERSION;

const currentYear = new Date().getFullYear();

const Footer = ({ gtm_id, communication_id = ``, contact_phone_number }) => {
  return (
    <StyledFooter>
      <FooterConstraints>
        <Centered id="t-copyright">
          Achievement is an Evidation Brand Copyright © {currentYear}
        </Centered>
        <Centered>
          <ContactModal contact_phone_number={contact_phone_number}>
            {({ showModal }) => (
              <FooterLink onClick={showModal}>Contact Us</FooterLink>
            )}
          </ContactModal>
          {communication_id !== `` && <p>{communication_id}</p>}
          <FooterLink href="https://www.myachievement.com/terms">
            <span id="t-terms">Terms & Conditions</span>
          </FooterLink>
          <FooterLink href="https://www.myachievement.com/privacy">
            <span id="t-privacy">Privacy Policy</span>
          </FooterLink>
        </Centered>
        <Centered>
          <Logo style={{ marginBottom: '5px' }} />
        </Centered>
        <HiddenTimestamp>{`${builtAt}`}</HiddenTimestamp>
        {gtm_id && <GoogleTagManager gtmId={gtm_id} />}
      </FooterConstraints>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  gtm_id: undefined,
  communication_id: ``,
  contact_phone_number: ``,
};

Footer.propTypes = {
  communication_id: PropTypes.string,
};

export default Footer;

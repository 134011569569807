import {
  BASE_HREF,
  COOKIE_ACM_ID,
  COOKIE_PARTICIPANT_AUTH,
  COOKIE_PORTAL_EMAIL,
  STUDY_ID,
  TRIAGE_API_VERSION,
  TRIAGE_ENDPOINT,
} from '../../constants';
import api, { getPreviewToken } from '../../services';
import _ from 'lodash';
import { RSAA } from 'redux-api-middleware';
import { getUrlParams } from '../../utils';
import { logApiError } from '../../utils/errorLogger';
import {
  REDUX_API_FAILURE,
  log_failure_with_request_data,
} from '../errors/actions';

const baseURL = `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/`;

const v3URL = `${TRIAGE_ENDPOINT}/api/v3/`;

const REQUEST = 'USER/REQUEST';
const SUCCESS = {
  type: 'USER/LOGIN',
  payload: (action, state, res) => res.json().then(newres => newres),
};
const FAILURE = REDUX_API_FAILURE;

const authHeader = (auth_token = '') => ({
  'Content-Type': 'application/json',
  'Participant-Auth-Token': auth_token,
  'Preview-Token': getPreviewToken(),
});

export const defaultMessage = `An error has occurred. Please try again in a few minutes, or click "Contact Us" for assistance.`;
export const loginFailed =
  'Login failed; invalid email or password. Please try again. If you forgot your password, please click the “Forgot Your Password” button.';

export const parseLoginError = response => {
  switch (response.status) {
    case 401:
      return loginFailed;
    case 404:
    case 500:
      return defaultMessage;
    default:
      return defaultMessage;
  }
};

const loginError = payload => ({
  type: 'ERRORS/ADD',
  payload: (action, state, response) => {
    logApiError({
      action,
      state,
      response,
      request: payload,
    });
    return { message: parseLoginError(response) };
  },
});

export const loginUser = (payload, enrollment_identifier, auth_token) => {
  return {
    [RSAA]: {
      endpoint: baseURL + `enrollments/sign_in`,
      method: `POST`,
      body: JSON.stringify(payload),
      headers: authHeader(auth_token),
      types: [REQUEST, SUCCESS, loginError(payload)],
    },
  };
};

export const authenticateUser = (
  payload,
  enrollment_identifier,
  auth_token,
) => ({
  [RSAA]: {
    endpoint: v3URL + `enrollments/${enrollment_identifier}/reauthenticate`,
    method: `POST`,
    body: JSON.stringify(payload),
    headers: authHeader(auth_token),
    types: [REQUEST, SUCCESS, loginError(payload)],
  },
});

export const updateUser = (payload, enrollment_identifier, auth_token) => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments/${enrollment_identifier}`,
    method: `PUT`,
    body: JSON.stringify({
      user_data: { ...payload },
    }),
    headers: authHeader(auth_token),
    types: [REQUEST, SUCCESS, log_failure_with_request_data(payload)],
  },
});

export const createUser = payload => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments`,
    method: `POST`,
    body: JSON.stringify({
      user_data: { ...payload, ...getUrlParams(window.location.search) },
    }),
    headers: authHeader(),
    types: [REQUEST, SUCCESS, FAILURE],
    credentials: `same-origin`,
  },
});

export const getUser = (enrollment_identifier, auth_token) => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments/${enrollment_identifier}`,
    method: `GET`,
    headers: authHeader(auth_token),
    types: [REQUEST, SUCCESS, FAILURE],
  },
});

export const setUser = participant => ({
  type: 'USER/LOGIN',
  payload: participant,
});

export const deleteOldestMessage = (enrollment_identifier, auth_token) => ({
  [RSAA]: {
    endpoint:
      baseURL + `enrollments/${enrollment_identifier}/delete_oldest_message`,
    method: `PUT`,
    headers: authHeader(auth_token),
    types: [REQUEST, SUCCESS, FAILURE],
  },
});

export const paramString = (list = [], urlParams) =>
  `?${list
    .map(param => urlParams[param] && `${param}=${urlParams[param]}`)
    .filter(e => e)
    .join('&')}`;

export const getSurvey = (slug, urlParams) => {
  return {
    [RSAA]: {
      endpoint:
        v3URL +
        `surveys/${slug}${paramString(
          ['enrollment_identifier', 'occurrence', 'auth_token'],
          urlParams,
        )}`,
      method: 'GET',
      types: [
        'USER/REQUEST',
        {
          type: 'USER/LOGIN',
          payload: (action, state, res) =>
            res.json().then(newres => ({
              nodes: [newres],
              layout: newres.layout,
            })),
        },
        log_failure_with_request_data(urlParams),
      ],
    },
  };
};

export const submitSurveyEndpoint = (slug, params) =>
  v3URL + `surveys/${slug}/answers${paramString(['auth_token'], params)}`;

export const submitSurvey = (contributions, slug, urlParams) => ({
  [RSAA]: {
    endpoint: submitSurveyEndpoint(slug, urlParams),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      enrollment_identifier: urlParams.enrollment_identifier,
      occurrence: urlParams.occurrence,
      contributions,
    }),
    types: [
      'SURVEY/SUBMIT',
      {
        type: 'SURVEY/SUBMIT_SUCCESS',
        payload: (action, state, res) =>
          res.json().then(newres => ({
            nodes: [newres],
          })),
      },
      REDUX_API_FAILURE,
    ],
  },
});

export const closeInteractionEndpoint = auth_token =>
  v3URL + `interactions/close?auth_token=${auth_token}`;

export const closeInteraction = auth_token => ({
  [RSAA]: {
    endpoint: closeInteractionEndpoint(auth_token),
    method: 'PATCH',
    types: [
      'INTERACTION/CLOSE_REQUEST',
      {
        type: 'INTERACTION/CLOSE_SUCCESS',
        payload: (action, state, res) => res,
      },
      REDUX_API_FAILURE,
    ],
  },
});

export const submitSurveyAndCloseInteraction = (contributions, slug) => {
  const urlParams = getUrlParams(window.location.search);
  return async (dispatch, getState) => {
    const submitSurveyResponse = await dispatch(
      submitSurvey(contributions, slug, urlParams),
    );

    // triage returns an empty form after receiving the last page submission
    const surveyCompleted = _.isEmpty(
      _.get(submitSurveyResponse, 'payload.nodes[0].content.form'),
    );
    if (!submitSurveyResponse.error && surveyCompleted) {
      const closeInteractionResponse = await dispatch(
        closeInteraction(urlParams.auth_token),
      );
      if (closeInteractionResponse.error)
        submitSurveyResponse.error = 'Failed to close interaction';
    }
    return submitSurveyResponse;
  };
};

export const submitScreener = (
  contributions,
  enrollment_identifier,
  node_id,
  auth_token,
) => ({
  [RSAA]: {
    endpoint: `${v3URL}enrollments/${enrollment_identifier}/surveys/${node_id}/answers`,
    method: 'POST',
    headers: authHeader(auth_token),
    body: JSON.stringify({ contributions }),
    types: [
      'SURVEY/SUBMIT',
      {
        type: 'SURVEY/SUBMIT_SUCCESS',
        payload: (action, state, res) => res.json().then(newres => newres),
      },
      REDUX_API_FAILURE,
    ],
  },
});

export const emitEvent = (data, enrollment_identifier, auth_token) => {
  return {
    [RSAA]: {
      endpoint: v3URL + `enrollments/${enrollment_identifier}/emit_event`,
      method: `POST`,
      body: JSON.stringify(data),
      headers: authHeader(auth_token),
      types: [REQUEST, SUCCESS, log_failure_with_request_data(data)],
    },
  };
};

export const logoutUser = () => {
  api.localStorage.set({});
  api.cookie.remove(COOKIE_PARTICIPANT_AUTH);
  api.cookie.remove(COOKIE_PORTAL_EMAIL, { domain: '.myachievement.com' });
  api.cookie.remove(COOKIE_ACM_ID, { domain: '.myachievement.com' });
  window.location.href = BASE_HREF;
  return { type: 'USER/LOGOUT' };
};

export const establishUser = payload => ({ type: `USER/LOGIN`, payload });

export const updateAcmInfo = payload => ({ type: 'USER/UPDATE_ACM', payload });

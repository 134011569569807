import { STUDY_ID, TRIAGE_ENDPOINT } from '../../constants';

import { RSAA, getJSON } from 'redux-api-middleware';
import { getPreviewToken } from '../../services';
import { REDUX_API_FAILURE } from '../errors/actions';

const authHeader = (limited_token = '') => ({
  'Content-Type': 'application/json',
  'Preview-Token': getPreviewToken(),
  'Limited-Token': limited_token,
});

const landingUrl = () => {
  return `${TRIAGE_ENDPOINT}/api/v3/studies/${STUDY_ID}/landing`;
};

export const getLanding = () => {
  return {
    [RSAA]: {
      endpoint: landingUrl(),
      method: 'GET',
      headers: authHeader(),
      types: [
        'LANDING/REQUEST',
        {
          type: 'LANDING/RECEIVE',
          payload: (action, state, res) => {
            return getJSON(res).then(json => json);
          },
        },
        REDUX_API_FAILURE,
      ],
    },
  };
};

import styled, { css } from 'styled-components';

const secondary = css`
  font-size: 16px;
  border-radius: 3px;
  padding: 14px 20px;
  height: 60px;
  width: 100%;
  color: #000;
  background-image: linear-gradient(to bottom, #fafafa, #f5f5f5);
  border: solid 1px #e6e6e6;

  :hover {
    background-image: linear-gradient(to bottom, #f2f2f2, #ededed);
    border: solid 1px #dedede;
  }
  :active,
  .active {
    background-image: linear-gradient(to top, #fafafa, #f5f5f5);
    border: solid 1px #e6e6e6;
  }
  :disabled,
  :disabled:hover {
    background-image: linear-gradient(to bottom, #fafafa, #f5f5f5);
    border: solid 1px #e6e6e6;
    color: #a6a6a6;
  }
`;

export default styled.button`
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  ${p => p.secondary && secondary}
`;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.regexp.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.generateValidateTimeRange = exports.generateValidateDateRange = exports.validateTimeOnly = exports.validateTime = exports.validateDateOnly = exports.validateDate = exports.validateDateTimeFormat = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _lodash = _interopRequireDefault(require("lodash"));

var _index = require("./index");

/*
  Redux-form validations
*/
var date_regex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/;
var hours_regex = /(2[0-3]|[01][0-9])/;
var min_regex = /[0-5][0-9]/;
var seconds_regex = /[0-5][0-9]/;
var time_zone_regex = /(-|\+)[0-9]{2}:[0-5][0-9]/;
var time_regex = RegExp(hours_regex.source + ':' + min_regex.source + ':' + seconds_regex.source + time_zone_regex.source);
var datetime_regex = RegExp('^' + date_regex.source + 'T' + time_regex.source + '$');

var validateDateTimeFormat = function validateDateTimeFormat(value) {
  if (value) {
    return datetime_regex.test(value) ? undefined : 'Please select a date and time';
  }
};

exports.validateDateTimeFormat = validateDateTimeFormat;

var validateDate = function validateDate(value) {
  if (value) {
    return date_regex.test(value) ? undefined : 'Please enter a valid date';
  }
};

exports.validateDate = validateDate;

var validateDateOnly = function validateDateOnly(value) {
  if (value) {
    return RegExp('^' + date_regex.source + '$').test(value) ? undefined : 'Please enter a valid date';
  }
};

exports.validateDateOnly = validateDateOnly;

var validateTime = function validateTime(value) {
  if (value) {
    return time_regex.test(value) ? undefined : 'Please enter a valid time';
  }
};

exports.validateTime = validateTime;

var validateTimeOnly = function validateTimeOnly(value) {
  if (value) {
    return RegExp('^' + time_regex.source + '$').test(value) ? undefined : 'Please enter a valid time';
  }
};

exports.validateTimeOnly = validateTimeOnly;

var generateValidateDateRange = function generateValidateDateRange(props) {
  return function (value) {
    if (!value) return undefined;
    var dateString = value.split('T')[0];
    var input = (0, _moment["default"])(dateString, 'YYYY-MM-DD').toDate();
    var disabledDates = (0, _index.disabledDatesFromProps)(props);

    if (disabledDates.before && disabledDates.after && (input < disabledDates.before || disabledDates.after < input)) {
      return "Please enter a date in the range ".concat((0, _moment["default"])(disabledDates.before).format('M/D/YYYY'), " - ").concat((0, _moment["default"])(disabledDates.after).format('M/D/YYYY'));
    } else if (disabledDates.before && input < disabledDates.before) {
      return "Please enter a date after ".concat((0, _moment["default"])(disabledDates.before).format('M/D/YYYY'));
    } else if (disabledDates.after && disabledDates.after < input) {
      return "Please enter a date before ".concat((0, _moment["default"])(disabledDates.after).format('M/D/YYYY'));
    }
  };
};

exports.generateValidateDateRange = generateValidateDateRange;

var formatTimeRange = function formatTimeRange(hour_start, hour_end) {
  var start = (0, _moment["default"])(hour_start, 'H').format('h:mm a');
  var end = (0, _moment["default"])(hour_end, 'H').format('h:mm a');
  return "".concat(start, " - ").concat(end);
};

var generateValidateTimeRange = function generateValidateTimeRange(_ref) {
  var datetimepicker_hours_start = _ref.datetimepicker_hours_start,
      datetimepicker_hours_end = _ref.datetimepicker_hours_end,
      datetimepicker_disable_date = _ref.datetimepicker_disable_date;
  return function (value) {
    if (!value) return undefined;
    if (_lodash["default"].isNull(datetimepicker_hours_start) || _lodash["default"].isNull(datetimepicker_hours_end)) return undefined;
    if (_lodash["default"].isUndefined(datetimepicker_hours_start) || _lodash["default"].isUndefined(datetimepicker_hours_end)) return undefined;
    var error = "Please enter a time in the range ".concat(formatTimeRange(datetimepicker_hours_start, datetimepicker_hours_end));
    var timeString = datetimepicker_disable_date ? value : value.split('T')[1];
    var parsedTime = (0, _moment["default"])(timeString, 'HH:mm:ssZ');
    var hour = parsedTime.hour();
    var minutes = parsedTime.minutes();

    if (hour < datetimepicker_hours_start || datetimepicker_hours_end < hour) {
      return error;
    } else if (hour === datetimepicker_hours_end) {
      return minutes === 0 ? undefined : error;
    }
  };
}; // component dictionary api - this gets called in CP to generate validations array
// generate validations based on mask type


exports.generateValidateTimeRange = generateValidateTimeRange;

var validations = function validations(props) {
  var validationList = [];
  if (props.datetimepicker_disable_date && props.datetimepicker_disable_time) return validationList;

  if (props.datetimepicker_disable_date) {
    validationList.push(validateTimeOnly, generateValidateTimeRange(props));
  } else if (props.datetimepicker_disable_time) {
    validationList.push(validateDateOnly, generateValidateDateRange(props));
  } else {
    validationList.push(validateTime, validateDate, validateDateTimeFormat, generateValidateDateRange(props), generateValidateTimeRange(props));
  }

  return validationList;
};

exports.validations = validations;
import { Container, CommonSpacer } from '../../../components';
import styled, { css } from 'styled-components';

export const LandingPageWidth = `1260px`;
export const LandingSidePadding = `28`;
export const LandingPadding = `0 ${LandingSidePadding}px`;

export const LandingContainer = styled(Container)`
  max-width: ${LandingPageWidth};
  display: flex;
  flex-flow: column nowrap;
`;

export const HeroContainer = styled(Container)`
  max-width: ${LandingPageWidth};
  margin: 30px 0;
  flex: 1 1 auto;
  padding: 30px 0;
  width: 100%;
`;

export const HeroContentWrapper = styled.div`
  flex: 1 1 auto;
  margin: 10px 0;

  p,
  span {
    color: #2a3f53 !important;
    font-family: Heebo;
    font-size: 20px;
    line-height: 28px;
    margin: 14px 0;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const HeroContent = styled.div`
  width: 50%;
  min-width: 250px;
  display: flex;
  flex-flow: column nowrap;

  @media (max-width: 600px) {
    align-items: stretch;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
  ${CommonSpacer};

  span {
    /* fix for markdown with only one line rendering as a span tag, not p */
    margin-bottom: 10px;
    display: block;
  }

  input {
    height: 50px;
    font-size: 20px;
  }
`;

export const HeroButtons = styled.div`
  min-height: 160px;
  flex: 1 1 auto;
  @media (min-width: 550px) {
    width: 100%;
  }
  @media (min-width: 775px) {
    width: 100%;
  }
  @media (min-width: 900px) {
    max-width: 376px;
  }
`;

export const HeroDisabledContent = styled(HeroContent)`
  min-height: auto;
  width: 100%;

  p {
    font: normal 24px/36px Heebo, sans-serif;
  }
`;

export const HeroDisabledBanner = styled.div`
  padding: ${LandingPadding};
  width: 100%;
  background: #5b6b7d;
  color: #ffffff;

  a {
    color: #ffffff;
  }
`;

const mobile_hero_disabled = css`
  @media (max-width: 415px) {
    background-image: none;
  }
`;

export const Hero = styled.div`
  padding: ${LandingPadding};
  flex: 1 1 auto;

  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: #f8f8f8;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props =>
    props.hero_image_path === ``
      ? `url("https://s3.amazonaws.com/assets.myachievement.com/study/humana_achievement.jpg")`
      : `url(${props.hero_image_path})`};
  border-bottom: ${p => (p.enrollmentIsClosed ? `0` : `1px solid #e6e6e6`)};

  ${p => p.hero_image_mobile_disabled && mobile_hero_disabled};
`;

export const HeroTitle = styled.h1`
  color: #2a3f53;
  font-family: 'Adamina', serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 49px;
  margin-bottom: 5px;

  @media (max-width: 400px) {
    font-size: 24px;
    line-height: 32px;
  }

  ${CommonSpacer};
`;

export const OptionalBrandingDiv = styled.div`
  background-color: #f2f5f6;
  text-align: center;
  img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 120px;
  }
`;

export const HowYouHelpImage = styled.img`
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  @media (min-width: 775px) {
    width: 26.31579%;
    float: left;
    margin-right: 5.26316%;
    margin-bottom: 0;
  }
`;

export const HowYouHelpContent = styled.div`
  width: 100%;
  float: right;
  margin-right: 0;
  @media (min-width: 775px) {
    width: 68.42105%;
    float: right;
    margin-right: 0;
  }

  ol,
  ul {
    padding-left: 25px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
`;

export const FaqContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;

  bold,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  h2 {
    font-family: 'Adamina', serif;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 1.6em;
  }

  ol,
  ul {
    padding-left: 25px;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin-bottom: 3px;
  }
`;

export const ThirdPartyLoginDiv = styled.div`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: center;

  p {
    line-height: 1rem;
  }

  .kep-login-facebook {
    width: 100%;
    height: 60px;
    font-size: 1rem;
    font-weight: 700;
    white-space: nowrap;

    :hover {
      opacity: 0.95;
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
  }

  .error {
    line-height: 1rem;
  }
`;

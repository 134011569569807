"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.reduce");

require("core-js/modules/es.array.some");

require("core-js/modules/es.function.name");

require("core-js/modules/es.parse-int");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.match");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/es.string.trim");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateBloodPressure = exports.todaysDateMustBeChecked = exports.singleCheckboxMustBeChecked = exports.humanaLengthValidation = exports.maxFileSize = exports.maxFilesUpload = exports.noEmptyFileArray = exports.selectNotDefaultValue = exports.noEmptyArray = exports.zipValidation = exports.dateValidation = exports.timeValidation = exports.emailValidation = exports.phone = exports.includedInMedicationList = exports.required = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _constants = require("./constants");

var _lodash = _interopRequireDefault(require("lodash"));

var validateEmail = function validateEmail(email) {
  return email.match(_constants.emailRegex);
};

var validatePhone = function validatePhone(phone) {
  return phone.match(_constants.phoneRegex);
};

var validateTime = function validateTime(time) {
  return time.match(_constants.timeRegex);
};

var validateZip = function validateZip(zip) {
  return zip.match(_constants.zipRegex);
};

var getMaxFiles = function getMaxFiles(v) {
  return parseInt(_lodash["default"].has(v[0], 'maxFiles') ? v[0].maxFiles : _constants.defaultMaxFiles, 10);
};

var required = function required(value) {
  return !_lodash["default"].isDate(value) && !_lodash["default"].isBoolean(value) && !_lodash["default"].isNumber(value) && _lodash["default"].isEmpty(value) || _lodash["default"].isNaN(value) ? "This field is required" : undefined;
};

exports.required = required;

var includedInMedicationList = function includedInMedicationList(value) {
  return ['medication1', 'medication2'].includes(value.replace(/ /g, '').toLowerCase()) ? undefined : "This is not a valid medication";
};

exports.includedInMedicationList = includedInMedicationList;

var phone = function phone(v) {
  if (v && !validatePhone(v)) {
    return "This field must be in the format of 1111111111 or (111) 111-1111";
  }

  return undefined;
};

exports.phone = phone;

var emailValidation = function emailValidation(v) {
  if (v && !validateEmail(v)) {
    return "Your email address does not appear to be valid.";
  }

  return undefined;
};

exports.emailValidation = emailValidation;

var timeValidation = function timeValidation(v) {
  if (v && !validateTime(v)) {
    return "Please use the format 11:11 AM";
  }

  return undefined;
};

exports.timeValidation = timeValidation;

var dateValidation = function dateValidation() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

  if (v && "".concat(v).toLowerCase() === "invalid date") {
    return "Please select a day, month and year.";
  }

  return undefined;
};

exports.dateValidation = dateValidation;

var zipValidation = function zipValidation(v) {
  if (v) {
    if (v.length !== 5 || !validateZip(v)) {
      return "US zipcode must contain 5 digits.";
    }
  }

  return undefined;
};

exports.zipValidation = zipValidation;

var noEmptyArray = function noEmptyArray(v) {
  if (v && _lodash["default"].isEmpty(v)) {
    return "This is a required field.";
  }

  return undefined;
};

exports.noEmptyArray = noEmptyArray;

var selectNotDefaultValue = function selectNotDefaultValue(v) {
  if (v && _lodash["default"].isEqual(v, _constants.default_select_value)) {
    return "This is a required field.";
  }

  return undefined;
};

exports.selectNotDefaultValue = selectNotDefaultValue;

var noEmptyFileArray = function noEmptyFileArray(v) {
  if (v && _lodash["default"].isEmpty(v)) {
    var maxFiles = getMaxFiles(v);
    return "Files not uploaded due to number of files. Please upload up to ".concat(maxFiles, " photos and try again.");
  }

  return undefined;
};

exports.noEmptyFileArray = noEmptyFileArray;

var maxFilesUpload = function maxFilesUpload(v) {
  if (!_lodash["default"].isEmpty(v)) {
    var maxFiles = getMaxFiles(v);

    if (v.length > maxFiles) {
      return "You have seleected too many files.";
    }
  }

  return undefined;
};

exports.maxFilesUpload = maxFilesUpload;

var maxFileSize = function maxFileSize(v) {
  var output = !_lodash["default"].isEmpty(v) ? _lodash["default"].reduce(v, function (acc, file) {
    return file.exceedsFileSize ? [].concat((0, _toConsumableArray2["default"])(acc), [file.name]) : (0, _toConsumableArray2["default"])(acc);
  }, []) : [];
  return output.length > 0 ? "Files ".concat(output.join(", "), " exceeds the allowed file size.") : undefined;
};

exports.maxFileSize = maxFileSize;

var humanaLengthValidation = function humanaLengthValidation(v) {
  if (!_lodash["default"].isEmpty(v) && v !== "false") {
    var val = v.trim().replace(_constants.regexUnderscores, "");

    if (val.length < _constants.HumanaIDLength) {
      return "Your Humana ID must be an H plus ".concat(_constants.HumanaIDLength - 1, " numbers.");
    }
  }

  return undefined;
};

exports.humanaLengthValidation = humanaLengthValidation;

var singleCheckboxMustBeChecked = function singleCheckboxMustBeChecked(v) {
  if (v === true) {
    return undefined;
  }

  return "This must be checked to proceed.";
};

exports.singleCheckboxMustBeChecked = singleCheckboxMustBeChecked;

var todaysDateMustBeChecked = function todaysDateMustBeChecked(v) {
  if (!!v && _lodash["default"].isString(v) && !_lodash["default"].isEmpty(v)) {
    return undefined;
  }

  return "This must be checked to proceed.";
};

exports.todaysDateMustBeChecked = todaysDateMustBeChecked;

var normalizeMask = function normalizeMask() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return value.replace(/_/g, "");
};

var validateBloodPressure = function validateBloodPressure() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var currentValue = normalizeMask(v);
  var values = currentValue.split('/').map(function (split) {
    return split.replace('mm Hg', '').trim();
  });
  if (_lodash["default"].some(values, function (v) {
    return v.length === 0;
  })) return "This is a required field.";
  var systolic = parseInt(values[0]);
  var diastolic = parseInt(values[1], 10);
  if (systolic <= diastolic || diastolic > 130 || diastolic < 30 || systolic > 210 || systolic < 60) return "Please enter a valid blood pressure measurement";
};

exports.validateBloodPressure = validateBloodPressure;
import { logApiError } from '../../utils/errorLogger';

export const REDUX_API_FAILURE = {
  type: 'ERRORS/ADD',
  payload: (action, state, response) =>
    logApiError({ action, state, response }),
};

export const log_failure_with_request_data = request => ({
  type: 'ERRORS/ADD',
  payload: (action, state, response) =>
    logApiError({ action, state, response, request }),
});

export const addError = () => ({ type: 'ERRORS/ADD' });
export const clearErrors = () => ({ type: 'ERRORS/CLEAR' });

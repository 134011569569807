"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonTypes = exports.TextLabelStyles = exports.TextInputTypes = void 0;
var TextInputTypes = ["dark", "light", "darker", "muted", "achievement", "sm_minimal", "transparent"];
exports.TextInputTypes = TextInputTypes;
var TextLabelStyles = ["light", "dark", "darker", "gray", "black", "achievement"];
exports.TextLabelStyles = TextLabelStyles;
var ButtonTypes = ["sm_primary", "sm_warning", "sm_default", "sm_default_blue", "lightblue", "pink", "default", "primary", "secondary", "warning", "confirm", "transparent", "flatprimary", "achievement", "achievement_text"];
exports.ButtonTypes = ButtonTypes;
import React, { Component } from 'react';

import { Button } from '@evidation/form-elements';
import CuriousPanda from './CuriousPanda';
import Modal from './styledModal';
import PropTypes from 'prop-types';
import { STUDY_ID } from '../constants';
import _ from 'lodash';
import api from '../services';
import { connect } from 'react-redux';
import detector from 'detector';
import styled from 'styled-components';

const Dialog = styled.div`
  box-sizing: border-box;
  position: relative;
  background: white;
  padding: 0 10;
  color: #333;
  border-radius: 3;
`;

const BtnGroup = styled.div`
  display: flex;
  margin: 20 0;

  button {
    padding: 14px 20px;
    font-size: 16px;
    height: 60px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #eff2f4;
    background: #fcfcfc;
    color: #cccccc;
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;

    &.active {
      color: #000;
      outline: solid 1px #698aab6b;
      z-index: 2;
    }
  }
`;

class ContactModal extends Component {
  constructor(props) {
    super(props);

    const participant = api.localStorage.get();

    this.state = {
      isShowingModal: false,
      opacity: 0,
      message: ``,
      header: props.contact_modal_heading,
      preferredMethod: props.contact_preferred_method,
      loading: false,
      participant,
    };
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  afterOpen = () =>
    setTimeout(() => {
      this.setState({ opacity: 1 });
    });

  handleClose() {
    this.setState({ isShowingModal: false, message: ``, loading: false });
    this.props.onClose();
  }

  submit(data) {
    const { participant } = this.state;
    const participant_auth_token = _.has(participant, `participant_auth_token`)
      ? participant.participant_auth_token
      : ``;

    const payload = {
      ...participant,
      ...data,
      study_slug: STUDY_ID,
      client_info: detector,
    };

    this.setState({ loading: true }, () => {
      api.triage
        .post(`support_requests`, payload, participant_auth_token)
        .then(({ data }) => {
          this.setState({
            isShowingModal: true,
            header: data.success ? `Thanks!` : `There was a problem`,
            message: data.success
              ? `We'll contact you as soon as we can`
              : data.error || data.message || `An unknown error occurred`,
          });
        });
    });
  }

  showModal = () => this.setState({ isShowingModal: true });

  render() {
    const {
      preferredMethod,
      header,
      message,
      isShowingModal,
      loading,
    } = this.state;
    const {
      children,
      contact_phone_number,
      contact_modal_message,
      contact_modal_heading,
    } = this.props;

    const form = [
      {
        input: [
          {
            name: 'email',
            label: 'Email Address',
            inputStyle: `achievement`,
            interface: 'email',
            required: true,
          },
          {
            inputStyle: `achievement`,
            name: 'request_content',
            label: 'Describe Your Request',
            interface: 'textarea',
            required: true,
          },
        ],
      },
    ];

    const initialValues = {
      email: _.has(this.state.participant, `email`)
        ? this.state.participant.email
        : ``,
    };

    return (
      <React.Fragment>
        {children({ showModal: this.showModal })}
        <Modal
          header={message === `` ? contact_modal_heading : header}
          isOpen={isShowingModal}
          closeModal={this.handleClose}
          afterOpen={this.afterOpen}
        >
          {message === `` && (
            <Dialog>
              <p className="text-center mb20">{contact_modal_message}</p>
              <div name="contactForm" className="multiple-choice">
                {preferredMethod && contact_phone_number !== `` && (
                  <fieldset className="mb20">
                    <div className="text-small">
                      Your Preferred Contact Method
                    </div>
                    <BtnGroup>
                      <button
                        className={preferredMethod === `Email` ? `active` : ``}
                        onClick={() =>
                          this.setState({
                            preferredMethod: `Email`,
                            isShowingModal: true,
                          })
                        }
                      >
                        Email
                      </button>
                      <button
                        className={preferredMethod === `Phone` ? `active` : ``}
                        onClick={() =>
                          this.setState({
                            preferredMethod: `Phone`,
                            isShowingModal: true,
                          })
                        }
                      >
                        Phone
                      </button>
                    </BtnGroup>
                  </fieldset>
                )}
                <div className="clear" />
                {preferredMethod === `Phone` && (
                  <h2 className="text-center mb20">
                    Call {contact_phone_number} to contact our phone support
                  </h2>
                )}
                {preferredMethod === `Email` && (
                  <CuriousPanda
                    form="contact_us"
                    onSubmit={this.submit}
                    schema={form}
                    initialValues={initialValues}
                  >
                    {({ valid, pristine, submitting }) => {
                      // Logic to determine if submit should be deactivated
                      // in this instance we are checking to see if we have initial values defined
                      // as If we have already populated the form (for CRF ONLY), it is ready to be submitted
                      // most other cases do not require this.
                      const allowSubmit =
                        !valid ||
                        submitting ||
                        (_.isEmpty(initialValues) && pristine);

                      return (
                        <Button
                          isLoading={submitting || loading}
                          buttonStyle="achievement"
                          id="t-submit_button"
                          type="submit"
                          disabled={allowSubmit}
                        >
                          Send Request
                        </Button>
                      );
                    }}
                  </CuriousPanda>
                )}
              </div>
            </Dialog>
          )}
          {message !== `` && <p className="text-center mb20">{message}</p>}
        </Modal>
      </React.Fragment>
    );
  }
}

ContactModal.defaultProps = {
  contact_modal_heading: `Need help? Get in touch.`,
  contact_phone_number: ``,
  contact_preferred_method: `Email`,
  contact_modal_message: `If you have questions or run into any issues during the study, please contact us. We're here to help!`,
  onClose: () => {},
};

ContactModal.propTypes = {
  onClose: PropTypes.func,
  contact_phone_number: PropTypes.string,
  children: PropTypes.func,
};

export default connect(({ meta }) => ({ ...meta }), null)(ContactModal);

import appsignal, { logError } from '@evidation/logger';
import { ApiError } from 'redux-api-middleware';
import _ from 'lodash';

const IGNORED_ERRORS = [404, 401, 429];

export const logApiError = ({
  action = {},
  state = {},
  response = {},
  request = {},
}) => {
  const { participant = {}, meta = {} } = state;
  const apiAction = action['@@redux-api-middleware/RSAA'];

  const span = appsignal.createSpan(span => {
    if (response && !_.includes(IGNORED_ERRORS, response.status)) {
      span.setError(new ApiError(response.status, response.statusText));
    } else if (!response) {
      span.setError(new Error('Network request failed'));
    }

    if (apiAction) {
      span.setTags({
        endpoint: apiAction.endpoint,
        redux_action: apiAction.types[0],
      });
    }

    return span.setTags({
      enrollment_id: participant.enrollment_identifier,
      slug: meta.slug,
      request: request,
      response: response,
    });
  });

  appsignal.send(span);

  switch (response.status) {
    case 500:
      return { message: 'An error occurred', statusCode: 500 };
    default:
      return {
        message: response.statusText,
        statusCode: response.status,
      };
  }
};

export default {
  logError,
  logApiError,
};

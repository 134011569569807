import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import App from './app';
import { BASE_HREF } from './constants';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store } from './redux';
import { render } from 'react-dom';
import ErrorBoundary from './errorBoundary';

render(
  <Provider store={store}>
    <BrowserRouter basename={BASE_HREF}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById(`root`),
);
